/* import __COLOCATED_TEMPLATE__ from './all-sources-banner.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */
import templateOnlyComponent from '@ember/component/template-only';
import type { SourceCardConfig } from './discovery-banner';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    sourceCardConfigs: SourceCardConfig[];
    styleForContent?: boolean;
  };
}

const AllSourcesBanner = templateOnlyComponent<Signature>();
export default AllSourcesBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::AllSourcesBanner': typeof AllSourcesBanner;
  }
}
