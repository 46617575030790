/* import __COLOCATED_TEMPLATE__ from './collection-names.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import KnowledgeHubContentWrapper from 'embercom/models/content-service/knowledge-hub-content-wrapper';
import ContentFragment from 'embercom/models/content-service/content-fragment';
import type Store from '@ember-data/store';
import type ArticleGroup from 'embercom/models/articles/article-group';
import { EntityType } from 'embercom/models/data/entity-types';

interface Args {
  tableRow: KnowledgeHubContentWrapper | ContentFragment;
}

const NUMBER_OF_COLLECTIONS_TO_DISPLAY = 2;

export default class CollectionNames extends Component<Args> {
  @service declare store: Store;

  get isArticle(): boolean {
    return this.args.tableRow.contentType === EntityType.ArticleContent;
  }

  get collectionIds(): number[] {
    if (this.args.tableRow instanceof KnowledgeHubContentWrapper) {
      return this.args.tableRow.contents.firstObject!.contentData?.collection_ids ?? [];
    }
    if (this.args.tableRow instanceof ContentFragment) {
      return this.args.tableRow.contentData?.collection_ids ?? [];
    }
    return [];
  }

  get collections(): ArticleGroup[] {
    let allCollections = this.store.peekAll('articles/article-group');
    let collections = this.collectionIds.map((id: number) => {
      return allCollections.find((collection: ArticleGroup) => collection.id === String(id));
    });
    return collections.compact();
  }

  get collectionsToDisplay() {
    return this.collections.slice(0, NUMBER_OF_COLLECTIONS_TO_DISPLAY);
  }

  get moreCount(): number {
    return this.collections.length - NUMBER_OF_COLLECTIONS_TO_DISPLAY;
  }

  get moreCollections(): ArticleGroup[] {
    return this.collections.slice(NUMBER_OF_COLLECTIONS_TO_DISPLAY, this.collections.length);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::CollectionNames': typeof CollectionNames;
    'knowledge-hub/filterable-list/table/cells/collection-names': typeof CollectionNames;
  }
}
