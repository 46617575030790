/* import __COLOCATED_TEMPLATE__ from './sync-issues-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import { SyncIssuesFilterOptions } from 'embercom/lib/content-service/search-api';

const SYNC_ISSUES_FILTER_OPTION_TRANSLATIONS = {
  [SyncIssuesFilterOptions.HasSyncIssues]:
    'knowledge-hub.filterable-list.filters.sync-issues.has-sync-issues',
  [SyncIssuesFilterOptions.NoSyncIssues]:
    'knowledge-hub.filterable-list.filters.sync-issues.no-sync-issues',
};

interface Args {
  doSearch: (filterOption?: SyncIssuesFilterOptions) => void;
  selectedSyncIssueOption?: SyncIssuesFilterOptions;
  activeFilter?: string;
  removeFilter: (filterOption?: SyncIssuesFilterOptions) => void;
}
export default class SyncIssuesFilter extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare store: Store;

  get isOpen() {
    return this.args.activeFilter === 'syncIssues' && !this.args.selectedSyncIssueOption;
  }

  get app() {
    return this.appService.app;
  }

  get options() {
    return [
      {
        text: this.intl.t('knowledge-hub.filterable-list.filters.sync-issues.has-sync-issues'),
        value: SyncIssuesFilterOptions.HasSyncIssues,
      },
      {
        text: this.intl.t('knowledge-hub.filterable-list.filters.sync-issues.no-sync-issues'),
        value: SyncIssuesFilterOptions.NoSyncIssues,
      },
    ];
  }

  get selectedSyncIssueOption() {
    let selectedOption = this.args.selectedSyncIssueOption;
    return selectedOption
      ? this.intl.t(SYNC_ISSUES_FILTER_OPTION_TRANSLATIONS[selectedOption])
      : this.intl.t('knowledge-hub.filterable-list.filters.sync-issues.label');
  }

  @action removeFilter() {
    this.args.doSearch();
    this.args.removeFilter();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::SyncIssuesFilter': typeof SyncIssuesFilter;
    'knowledge-hub/filterable-list/filters/sync-issues-filter': typeof SyncIssuesFilter;
  }
}
