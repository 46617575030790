/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type Folder from 'embercom/models/content-service/folder';
import { EntityType } from 'embercom/models/data/entity-types';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

interface Args {
  showNewContentModal: () => void;
  folder?: Folder;
}

const CONTENT_ENTITY_TYPES = [
  EntityType.ArticleContent,
  EntityType.InternalArticle,
  EntityType.ContentSnippet,
];

export default class EmptyState extends Component<Args> {
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;

  get contentTypeTiles() {
    let tiles = CONTENT_ENTITY_TYPES.map((entityType) => {
      return {
        id: `${entityType}`,
        title: this.intl.t(
          `knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.${objectNames[entityType]}.title`,
        ),
        entityType,
        description: this.intl.t(
          `knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.${objectNames[entityType]}.description`,
        ),
      };
    });
    return tiles;
  }

  @action async createContent(entityType: EntityType) {
    this.knowledgeHubService.createContent(entityType, {
      folder_id: this.args.folder?.id,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::EmptyState': typeof EmptyState;
    'knowledge-hub/empty-state': typeof EmptyState;
  }
}
