/* import __COLOCATED_TEMPLATE__ from './created-by.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';

interface Args {
  activeContent: LocalizedKnowledgeContent;
}

const CreatedBy = templateOnlyComponent<Args>();
export default CreatedBy;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::DataSection::CreatedBy': typeof CreatedBy;
    'knowledge-hub/content-editor/shared/data-section/created-by': typeof CreatedBy;
  }
}
