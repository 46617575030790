/* import __COLOCATED_TEMPLATE__ from './article-published-state.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import KnowledgeHubContentWrapper from 'embercom/models/content-service/knowledge-hub-content-wrapper';
import ContentFragment from 'embercom/models/content-service/content-fragment';
import { EntityType } from 'embercom/models/data/entity-types';
import { ArticlePublishedStatuses } from 'embercom/lib/knowledge-hub/constants';
import type Store from '@ember-data/store';
interface Args {
  tableRow: KnowledgeHubContentWrapper | ContentFragment;
}

const stateToClasses: { [key: string]: string } = {
  [ArticlePublishedStatuses.DRAFT]: 'draft',
  [ArticlePublishedStatuses.PUBLISHED]: 'published',
  [ArticlePublishedStatuses.PUBLISHED_WITH_DRAFT]: 'published-with-draft',
};

const stateToTranslationKey: { [key: string]: string } = {
  [ArticlePublishedStatuses.DRAFT]:
    'knowledge-hub.filterable-list.list.columns.article-state.draft',
  [ArticlePublishedStatuses.PUBLISHED]:
    'knowledge-hub.filterable-list.list.columns.article-state.published',
  [ArticlePublishedStatuses.PUBLISHED_WITH_DRAFT]:
    'knowledge-hub.filterable-list.list.columns.article-state.published-with-draft',
};

export default class ArticlePublishedState extends Component<Args> {
  @service declare store: Store;

  get isArticle() {
    return this.args.tableRow.contentType === EntityType.ArticleContent;
  }

  get publishedState(): number | undefined {
    if (!this.isArticle) {
      return undefined;
    }

    if (this.args.tableRow instanceof KnowledgeHubContentWrapper) {
      return (
        this.args.tableRow.contents.firstObject!.contentData?.article_published_state ?? undefined
      );
    }
    if (this.args.tableRow instanceof ContentFragment) {
      return this.args.tableRow.contentData?.article_published_state ?? undefined;
    }
    return undefined;
  }

  get stateClasses(): string {
    if (this.publishedState === undefined) {
      return '';
    }

    return `o__${stateToClasses[this.publishedState]}`;
  }

  get stateDescription(): string {
    if (this.publishedState === undefined) {
      return '';
    }

    return stateToTranslationKey[this.publishedState];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::ArticlePublishedState': typeof ArticlePublishedState;
    'knowledge-hub/filterable-list/table/cells/article-published-state': typeof ArticlePublishedState;
  }
}
