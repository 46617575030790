/* import __COLOCATED_TEMPLATE__ from './empty-body.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
}

const EmptyBody = templateOnlyComponent<Signature>();
export default EmptyBody;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::View::EmptyBody': typeof EmptyBody;
    'knowledge-hub/content-editor/shared/view/empty-body': typeof EmptyBody;
  }
}
