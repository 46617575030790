/* import __COLOCATED_TEMPLATE__ from './content-type.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  entityType?: number;
}

const ContentType = templateOnlyComponent<Args>();
export default ContentType;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::DataSection::ContentType': typeof ContentType;
    'knowledge-hub/content-editor/shared/data-section/content-type': typeof ContentType;
  }
}
