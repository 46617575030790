/* import __COLOCATED_TEMPLATE__ from './tags.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import type Tag from 'embercom/models/tag';

interface Args {
  tags?: Tag[];
}

const NUMBER_OF_TAGS_TO_DISPLAY = 2;

export default class Tags extends Component<Args> {
  get tags() {
    return this.args.tags || [];
  }

  get tagNames(): string[] {
    return this.tags.map((tag: Tag) => tag.name);
  }

  get tagNamesToDisplay(): string[] {
    return this.tagNames.slice(0, NUMBER_OF_TAGS_TO_DISPLAY);
  }

  get moreCount(): number {
    return this.tagNames.length - NUMBER_OF_TAGS_TO_DISPLAY;
  }

  get moreTagNames(): string[] {
    return this.tagNames.slice(NUMBER_OF_TAGS_TO_DISPLAY, this.tagNames.length);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::Tags': typeof Tags;
    'knowledge-hub/filterable-list/table/cells/tags': typeof Tags;
  }
}
