/* import __COLOCATED_TEMPLATE__ from './list-count.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';
import type KnowledgeHubApi from 'embercom/lib/knowledge-hub/list-api';

interface Args {
  listApi: KnowledgeHubApi;
  showEmptyState: boolean;
  selectedCount: number;
  hasAppliedFilter: boolean;
  isBulkSelection: boolean;
}

const ListCount = templateOnlyComponent<Args>();
export default ListCount;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::ListCount': typeof ListCount;
    'knowledge-hub/filterable-list/list-count': typeof ListCount;
  }
}
