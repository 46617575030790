/* import __COLOCATED_TEMPLATE__ from './breadcrumbs.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import type Folder from 'embercom/models/content-service/folder';
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  folder?: Folder;
  title?: string;
}

const Breadcrumbs = templateOnlyComponent<Args>();
export default Breadcrumbs;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Breadcrumbs': typeof Breadcrumbs;
  }
}
