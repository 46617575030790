/* import __COLOCATED_TEMPLATE__ from './folder-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import { FolderFilterOption } from 'embercom/lib/content-service/search-api';

const FOLDER_FILTER_OPTION_TRANSLATIONS = {
  [FolderFilterOption.Any]: 'knowledge-hub.filterable-list.filters.folder.any',
  [FolderFilterOption.None]: 'knowledge-hub.filterable-list.filters.folder.none',
};

interface Args {
  doSearch: (filterOption?: FolderFilterOption) => void;
  selectedFilterOption?: FolderFilterOption;
  activeFilter?: string;
  removeFilter: (filterOption?: FolderFilterOption) => void;
}
export default class FolderFilter extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare store: Store;

  get isOpen() {
    return this.args.activeFilter === 'folder' && !this.args.selectedFilterOption;
  }

  get app() {
    return this.appService.app;
  }

  get options() {
    return [
      {
        text: this.intl.t('knowledge-hub.filterable-list.filters.folder.any'),
        value: FolderFilterOption.Any,
      },
      {
        text: this.intl.t('knowledge-hub.filterable-list.filters.folder.none'),
        value: FolderFilterOption.None,
      },
    ];
  }

  get selectedFolderName() {
    let selectedOption = this.args.selectedFilterOption;
    return selectedOption
      ? this.intl.t(FOLDER_FILTER_OPTION_TRANSLATIONS[selectedOption])
      : this.intl.t('knowledge-hub.filterable-list.filters.folder.label');
  }

  @action removeFilter() {
    this.args.doSearch();
    this.args.removeFilter();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::FolderFilter': typeof FolderFilter;
    'knowledge-hub/filterable-list/filters/folder-filter': typeof FolderFilter;
  }
}
