/* import __COLOCATED_TEMPLATE__ from './search.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';

import { type TaskGenerator } from 'ember-concurrency';
import type KnowledgeHubApi from 'embercom/lib/knowledge-hub/list-api';
import { action } from '@ember/object';

interface Args {
  searchTerm?: string;
  setSearchTerm: (searchTerm?: string) => void;
  listApi: KnowledgeHubApi;
  beforeSearch?: () => void;
}

const SEARCH_ANALYTICS_OBJECT = 'search';
const SEARCH_ANALYTICS_PLACE = 'knowledge_hub';

export default class Search extends Component<Args> {
  @service declare intercomEventService: $TSFixMe;

  @action beforeSearch() {
    this.args.beforeSearch?.();
  }

  @restartableTask
  *searchByTerm(filterItem: { name: string }): TaskGenerator<void> {
    this.args.setSearchTerm(filterItem.name);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'searched',
      object: SEARCH_ANALYTICS_OBJECT,
      place: SEARCH_ANALYTICS_PLACE,
    });
    this.beforeSearch();
    yield this.args.listApi.search(filterItem.name);
  }

  @restartableTask
  *resetSearchByTerm(): TaskGenerator<void> {
    this.args.setSearchTerm(undefined);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'reset_search',
      object: SEARCH_ANALYTICS_OBJECT,
      place: SEARCH_ANALYTICS_PLACE,
    });
    yield this.args.listApi.search(undefined);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Search': typeof Search;
    'knowledge-hub/search': typeof Search;
  }
}
