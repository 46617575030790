/* import __COLOCATED_TEMPLATE__ from './folder-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import { tracked } from '@glimmer/tracking';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { action } from '@ember/object';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    activeContent: LocalizedKnowledgeContent;
  };
}

export default class FolderSection extends Component<Signature> {
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare appService: $TSFixMe;

  @tracked moveToOpen = false;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    this.loadParentFolder();
  }

  async loadParentFolder() {
    await this.args.activeContent.parentContent.getParentMaybeFindAll();
  }

  get rootPage() {
    return this.args.activeContent.parentContent.isRoot;
  }

  get parentFolder() {
    return this.args.activeContent.parentContent.parent;
  }

  @action openMoveTo() {
    this.moveToOpen = true;
  }

  @action closeMoveTo() {
    this.moveToOpen = false;
  }

  get foldersLoading() {
    return this.args.activeContent.parentContent.loadingParent;
  }

  get contentIsEditable() {
    return this.args.activeContent.parentContent.isEditable;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::FolderSection': typeof FolderSection;
    'knowledge-hub/content-editor/folder-section': typeof FolderSection;
  }
}
