/* import __COLOCATED_TEMPLATE__ from './type-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { objectIcons } from 'embercom/models/data/matching-system/matching-constants';
import { type EntityType } from 'embercom/models/data/entity-types';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { type FinAIType } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  activeFilter?: string;
  availableTypes?: Array<EntityType>;
  selectedTypes?: Array<EntityType>;
  aiType?: FinAIType;
  doSearch: (objectTypes?: Array<EntityType>) => void;
}

const NUMBER_OF_OBJECT_TYPES_TO_DISPLAY = 2;

export default class TypeFilter extends Component<Args> {
  @service intl!: IntlService;
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;

  get isOpen() {
    return this.args.activeFilter === 'type' && !this.args.selectedTypes?.length;
  }

  get availableTypes() {
    return (
      this.args.availableTypes || this.knowledgeHubService.getSearchObjectTypes(this.args.aiType)
    );
  }

  get items() {
    let items = this.availableTypes.map((type) => {
      return {
        text: this.intl.t(`knowledge-hub.content-type-stamp.content-type.${type}`),
        icon: objectIcons[type],
        value: type,
        isSelected: this.args.selectedTypes?.includes(type),
      };
    });

    return [{ items }];
  }

  get selectedContentType() {
    if (this.args.selectedTypes && this.args.selectedTypes?.length > 0) {
      if (this.args.selectedTypes.length > NUMBER_OF_OBJECT_TYPES_TO_DISPLAY) {
        return this.intl.t(
          'knowledge-hub.filterable-list.filters.type.display-selected-item-and-count',
          {
            selectedItems: this.selectedItemsToDisplay,
            count: this.args.selectedTypes.length - NUMBER_OF_OBJECT_TYPES_TO_DISPLAY,
          },
        );
      }
      return this.intl.t('knowledge-hub.filterable-list.filters.type.display-selected-items', {
        selectedItems: this.selectedItemsToDisplay,
      });
    }

    return this.intl.t('knowledge-hub.filterable-list.filters.type.any-type');
  }

  get selectedItemsToDisplay() {
    return this.args.selectedTypes
      ?.slice(0, NUMBER_OF_OBJECT_TYPES_TO_DISPLAY)
      .map((type) => this.intl.t(`knowledge-hub.content-type-stamp.content-type.${type}`))
      .join(', ');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::TypeFilter': typeof TypeFilter;
    'knowledge-hub/filterable-list/filters/type-filter': typeof TypeFilter;
  }
}
