/* import __COLOCATED_TEMPLATE__ from './data-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { KnowledgeBaseContent } from 'embercom/objects/inbox/knowledge-base/content';
import type InternalArticle from 'embercom/models/content-service/internal-article';

interface Args {
  activeContent: InternalArticle;
}

export default class DataSection extends Component<Args> {
  get content() {
    return new KnowledgeBaseContent(this.args.activeContent as any);
  }

  get activeContent() {
    return this.args.activeContent;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::InternalNote::DataSection': typeof DataSection;
    'knowledge-hub/content-editor/internal-note/data-section': typeof DataSection;
  }
}
