/* import __COLOCATED_TEMPLATE__ from './created-at.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  createdAt: string | Date;
}

const CreatedAt = templateOnlyComponent<Args>();
export default CreatedAt;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::DataSection::CreatedAt': typeof CreatedAt;
    'knowledge-hub/content-editor/shared/data-section/created-at': typeof CreatedAt;
  }
}
