/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { type SyncSourceWrapperResponse } from 'embercom/services/knowledge-hub-service';

export type KnowledgeUsageSummary = {
  [key: number]: {
    entity_type: number;
    all: number;
    agent: number;
    copilot: number;
    help_center: number;
    sync_sources: SyncSourceWrapperResponse[];
  };
};

export type SyncSourceType =
  | 'notion'
  | 'guru'
  | 'confluence'
  | 'zendesk'
  | 'external_content'
  | 'zendesk_tickets';

export enum CardStatus {
  LIVE = 'live',
  SYNCING = 'syncing',
  ERROR = 'error',
}

export type ContentFilter = 'all' | 'ai-agent' | 'ai-copilot' | 'help-center';
