/* import __COLOCATED_TEMPLATE__ from './tags-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import templateOnlyComponent from '@ember/component/template-only';
import type Model from '@ember-data/model';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    activeContent: LocalizedKnowledgeContent & Model;
  };
}

const TagsSection = templateOnlyComponent<Signature>();
export default TagsSection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::TagsSection': typeof TagsSection;
    'knowledge-hub/content-editor/shared/tags-section': typeof TagsSection;
  }
}
