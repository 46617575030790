/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import InternalNotesComposerConfig from 'embercom/lib/internal-notes/composer-config';
import type InternalArticle from 'embercom/models/content-service/internal-article';

interface Args {
  activeContent: InternalArticle;
  defaultThemeColor: string;
}

export default class Editor extends Component<Args> {
  @service declare appService: any;

  @tracked prosemirrorComposer: any;

  constructor(owner: any, args: Args) {
    super(owner, args);
  }

  get app() {
    return this.appService.app;
  }

  get activeContent() {
    return this.args.activeContent;
  }

  get prosemirrorComposerConfig() {
    return new InternalNotesComposerConfig(this.app, this.activeContent.textDirection);
  }

  get activeContentBlocks() {
    return new BlocksDocument(this.activeContent.currentVersion?.jsonBlocksForEditor);
  }

  @action
  prosemirrorComposerReady(composer: any) {
    this.prosemirrorComposer = composer;
  }

  @action
  prosemirrorComposerChanged(newBlocksDocument: BlocksDocument) {
    this.activeContent.newOrCurrentVersion.set('jsonBlocks', newBlocksDocument.blocks);
    this.activeContent.currentVersion?.set('jsonBlocksForEditor', newBlocksDocument.blocks);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::InternalNote::Edit::Editor': typeof Editor;
    'knowledge-hub/content-editor/internal-note/edit/editor': typeof Editor;
  }
}
