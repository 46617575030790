/* import __COLOCATED_TEMPLATE__ from './review-settings.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type ConversationExtractionSettings from 'embercom/models/content-service/conversation-extraction-settings';

type ReviewSetting = 'required' | 'not-required';

interface Args {
  conversationExtractionSettings?: ConversationExtractionSettings;
}

export default class ReviewSettings extends Component<Args> {
  get selectedRadioValue(): ReviewSetting {
    return this.args.conversationExtractionSettings?.reviewRequired ? 'required' : 'not-required';
  }

  set selectedRadioValue(value: ReviewSetting) {
    if (!this.args.conversationExtractionSettings) {
      return;
    }
    this.args.conversationExtractionSettings.reviewRequired = value === 'required';
  }

  @action updateReviewSetting(isReviewRequired: boolean) {
    if (!this.args.conversationExtractionSettings) {
      return;
    }
    this.args.conversationExtractionSettings.reviewRequired = isReviewRequired;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Conversational::Settings::ReviewSettings': typeof ReviewSettings;
  }
}
