/* import __COLOCATED_TEMPLATE__ from './nav-root-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import { type TreeItem } from 'embercom/objects/tree-list';
import type Router from '@ember/routing/router-service';

interface Args {
  item: TreeItem;
}

export default class NavRootItem extends Component<Args> {
  @service declare permissionsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare router: Router;
  @tracked modalOpen = false;

  @action
  async openModal() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }

    this.modalOpen = true;
  }

  @action
  closeModal() {
    this.modalOpen = false;
  }

  @action
  toggleIsExpanded() {
    if (this.router.currentRouteName !== 'apps.app.knowledge-hub.all-content') {
      return undefined;
    }
    this.args.item.isExpanded = !this.args.item.isExpanded;

    this.intercomEventService.trackAnalyticsEvent({
      action: this.args.item.isExpanded ? 'expanded' : 'collapsed',
      object: 'nav_root_item',
      place: 'knowledge-hub',
      section: 'content-section',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Folders::Tree::NavRootItem': typeof NavRootItem;
    'knowledge-hub/folders/tree/nav-root-item': typeof NavRootItem;
  }
}
