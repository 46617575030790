/* import __COLOCATED_TEMPLATE__ from './language.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  languageCode?: string;
  setLocale: (languageCode: string) => void;
  isDisabled?: boolean;
}

const Language = templateOnlyComponent<Args>();

export default Language;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::DataSection::Language': typeof Language;
    'knowledge-hub/content-editor/shared/data-section/language': typeof Language;
  }
}
