/* import __COLOCATED_TEMPLATE__ from './conversation-attributes-selector.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import type Attribute from 'embercom/models/attribute';
import { inject as service } from '@ember/service';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  updateSelectedAttributes: (attributeValue: Attribute['identifier']) => void;
  removeSelectedAttribute: (attribute: Attribute) => void;
  selectedAttributes: Attribute[];
  dropdownAttributes: Attribute[];
  selectedMatchingType: string;
  updateSelectedMatchingType: (selectedMatchingType: string) => void;
  isDisabled: boolean;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

interface DropdownAttributeGroups {
  conversationAttributes: {
    text: string;
    value: string;
    icon: string;
  }[];
  companyAttributes: {
    text: string;
    value: string;
    icon: string;
  }[];
  personAttributes: {
    text: string;
    value: string;
    icon: string;
  }[];
}

export default class ConversationAttributesSelector extends Component<Signature> {
  @service declare intl: IntlService;

  get personAttributesAsDropdownOptions() {
    let attributes: DropdownAttributeGroups = {
      conversationAttributes: [],
      companyAttributes: [],
      personAttributes: [],
    };

    this.args.dropdownAttributes.forEach((attribute) => {
      if (attribute.isCompany) {
        attributes.companyAttributes.push({
          text: attribute.name,
          value: attribute.identifier,
          icon: attribute.icon,
        });
      } else if (attribute.isUser) {
        attributes.personAttributes.push({
          text: attribute.name,
          value: attribute.identifier,
          icon: attribute.icon,
        });
      } else {
        attributes.conversationAttributes.push({
          text: attribute.name,
          value: attribute.identifier,
          icon: attribute.icon,
        });
      }
    });

    return attributes;
  }

  get isConversationAttributesSelectorDisabled() {
    return this.args.isDisabled || this.args.dropdownAttributes.length === 0;
  }

  get groupList() {
    return [
      {
        heading: this.intl.t(
          'knowledge-hub.overview.sections.syncs.modals.matching-attributes.company-heading',
        ),
        isFilterable: true,
        items: this.personAttributesAsDropdownOptions.companyAttributes,
      },
      {
        heading: this.intl.t(
          'knowledge-hub.overview.sections.syncs.modals.matching-attributes.person-heading',
        ),
        isFilterable: true,
        items: this.personAttributesAsDropdownOptions.personAttributes,
      },
      {
        heading: this.intl.t(
          'knowledge-hub.overview.sections.syncs.modals.matching-attributes.conversation-heading',
        ),
        isFilterable: true,
        items: this.personAttributesAsDropdownOptions.conversationAttributes,
      },
    ];
  }

  get matchingTypeItems() {
    return [
      {
        text: 'and',
        value: 'and',
      },
      {
        text: 'or',
        value: 'or',
      },
    ];
  }

  getIconValue(icon: string): InterfaceIconName {
    return icon as InterfaceIconName;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ManageConversationsDrawer::ConversationAttributesSelector': typeof ConversationAttributesSelector;
    'knowledge-hub/manage-conversations-drawer/conversation-attributes-selector': typeof ConversationAttributesSelector;
  }
}
