/* import __COLOCATED_TEMPLATE__ from './data-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { KnowledgeBaseContent } from 'embercom/objects/inbox/knowledge-base/content';
import type ArticleContent from 'embercom/models/articles/article-content';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type ArticleMultilingual from 'embercom/models/article-multilingual';

interface Args {
  activeContent: ArticleContent;
}

export default class DataSection extends Component<Args> {
  @service declare helpCenterService: any;
  @tracked declare article: ArticleMultilingual;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.article = this.args.activeContent.article;
  }

  get activeContent() {
    return this.args.activeContent;
  }

  get content() {
    return new KnowledgeBaseContent(this.args.activeContent as any);
  }

  get isArticleDraft() {
    return this.args.activeContent.isDraft;
  }

  get isArticlePublished() {
    return this.args.activeContent.isPublished;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::DataSection': typeof DataSection;
    'knowledge-hub/content-editor/article-content/data-section': typeof DataSection;
  }
}
