/* import __COLOCATED_TEMPLATE__ from './macros.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import Component from '@glimmer/component';
import { type OverviewSectionArgs } from '../../overview';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from 'tracked-built-ins';
import type CopilotApi from 'embercom/services/copilot-api';
import type IntlService from 'ember-intl/services/intl';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

export default class Macros extends Component<OverviewSectionArgs> {
  @service declare router: RouterService;
  @service declare copilotApi: CopilotApi;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;

  @tracked showMacrosModal = this.args.showManageMacrosModal ?? false;

  @action closeModal() {
    this.copilotApi.refreshCopilotSettingsData();
    this.showMacrosModal = false;
    this.router.transitionTo(this.router.currentRouteName, {
      queryParams: { manageMacros: false },
    });
  }

  @action async onManageMacros() {
    try {
      await this.knowledgeHubService.ensurePermissions();
    } catch (e) {
      return;
    }
    this.copilotApi.refreshCopilotSettingsData();
    this.showMacrosModal = true;
  }

  @action goToMacros() {
    this.router.transitionTo('apps.app.settings.helpdesk.macros');
  }

  get macrosEnabled() {
    return this.copilotApi.copilotSettings?.use_macros ?? true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Sections::Macros': typeof Macros;
    'knowledge-hub/overview/sections/macros': typeof Macros;
  }
}
