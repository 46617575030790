/* import __COLOCATED_TEMPLATE__ from './save-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { get } from '@ember/object';
import Component from '@glimmer/component';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';

interface Args {
  activeContent: LocalizedKnowledgeContent;
  saveActiveContent: () => void;
}

export default class SaveButton extends Component<Args> {
  get saving(): boolean {
    return !!get(this.args.activeContent, 'isSaving');
  }

  set saving(_value) {
    // noop
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::Header::SaveButton': typeof SaveButton;
    'knowledge-hub/content-editor/shared/header/save-button': typeof SaveButton;
  }
}
