/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import type ArticleContent from 'embercom/models/articles/article-content';
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface Args {
  activeContent: ArticleContent;
  editActiveContent: () => void;
  unpublishActiveContent: () => void;
  showSidePanel: boolean;
  toggleSidePanel: () => void;
  publishActiveContent: () => Promise<void>;
}

export default class Header extends Component<Args> {
  closeSidePanelOnResize = true;
  @action closeSidePanel(header: HTMLElement) {
    let headerHasScroll = header.offsetWidth < header.scrollWidth;
    let closeSidePanel = headerHasScroll && this.closeSidePanelOnResize && this.args.showSidePanel;
    if (closeSidePanel) {
      this.args.toggleSidePanel();
    }
    this.closeSidePanelOnResize = !headerHasScroll && this.args.showSidePanel;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::View::Header': typeof Header;
    'knowledge-hub/content-editor/article-content/view/header': typeof Header;
  }
}
