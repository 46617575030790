/* import __COLOCATED_TEMPLATE__ from './internal-articles.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { type OverviewSectionArgs } from '../../overview';

import templateOnlyComponent from '@ember/component/template-only';

const InternalArticles = templateOnlyComponent<OverviewSectionArgs>();
export default InternalArticles;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Sections::InternalArticles': typeof InternalArticles;
    'knowledge-hub/overview/sections/internal-articles': typeof InternalArticles;
  }
}
