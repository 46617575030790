/* import __COLOCATED_TEMPLATE__ from './stats-tab.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type ContentStatistic from 'embercom/models/outbound/content-statistic';
import { STATISTIC_KEY_TO_AGGREGATION_VALUES } from 'embercom/components/knowledge-hub/content-editor/shared/reporting-section';
import { type StatisticKeyName } from 'embercom/models/data/outbound/types';

interface Signature {
  Args: {
    statKey: StatisticKeyName;
    stat: ContentStatistic | undefined;
    isSelected: boolean;
  };
}

type AggregationItem = {
  value: string;
  count: number;
};

export default class StatsTab extends Component<Signature> {
  @service declare intl: IntlService;

  get heading() {
    switch (this.args.statKey) {
      case 'articleContentView':
        return this.intl.t('knowledge-hub.content-editor.shared.reporting.tabs.views');
      case 'replies':
        return this.intl.t('knowledge-hub.content-editor.shared.reporting.tabs.conversations');
      case 'reactions':
        return this.intl.t('knowledge-hub.content-editor.shared.reporting.tabs.reactions');
      default:
        return '';
    }
  }

  get statValue() {
    return this.args.stat?.value ?? 0;
  }

  get availableAggregations(): AggregationItem[] {
    if (Array.isArray(this.args.stat?.subaggregations)) {
      return this.args.stat?.subaggregations as AggregationItem[];
    }
    return [];
  }

  get hasAggregations() {
    return Object.keys(STATISTIC_KEY_TO_AGGREGATION_VALUES).includes(this.args.statKey);
  }

  get subaggregations() {
    let aggregationValues = STATISTIC_KEY_TO_AGGREGATION_VALUES[this.args.statKey] ?? [];
    return aggregationValues.map((value) => {
      let count = 0;
      if (this.statValue > 0) {
        let aggregatedCount =
          this.availableAggregations.find((aggregation) => aggregation.value === value)?.count ?? 0;
        count = Math.round((aggregatedCount * 100) / this.statValue);
      }
      return { value, count };
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::StatsTab': typeof StatsTab;
    'knowledge-hub/content-editor/shared/StatsTab': typeof StatsTab;
  }
}
