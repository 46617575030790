/* import __COLOCATED_TEMPLATE__ from './disable-feature-modal.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import templateOnlyComponent from '@ember/component/template-only';

interface DisableFeatureModalArgs {
  isOpen: boolean;
  disableFeature: () => void;
  onClose: () => void;
}

const DisableFeatureModal = templateOnlyComponent<DisableFeatureModalArgs>();
export default DisableFeatureModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Conversational::DisableFeatureModal': typeof DisableFeatureModal;
  }
}
