/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import type ArticleContent from 'embercom/models/articles/article-content';
import { MAX_SUMMARY_SIZE, MAX_PREVIEW_SIZE } from 'embercom/models/article';
import ArticlesComposerConfig from 'embercom/lib/articles/composer-config';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import type ClipboardService from 'embercom/services/clipboard-service';
import ENV from 'embercom/config/environment';
import { EntityType } from 'embercom/models/data/entity-types';
import { post } from 'embercom/lib/ajax';

interface Args {
  activeContent: ArticleContent;
  defaultThemeColor: string;
}

export default class Editor extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: any;
  @service declare helpCenterService: any;
  @service declare clipboardService: ClipboardService;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: $TSFixMe;

  @tracked activeContentBlocksDocument: BlocksDocument;
  @tracked prosemirrorComposer: $TSFixMe;
  maxSummarySize = MAX_SUMMARY_SIZE;
  clipboard: ClipboardJS | undefined;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.activeContentBlocksDocument = new BlocksDocument(this.activeContentBlocks);
  }

  get themeColor() {
    return this.helpCenterService?.site?.themeColor || this.args.defaultThemeColor;
  }

  get app() {
    return this.appService.app;
  }

  get activeContent() {
    return this.args.activeContent;
  }

  get prosemirrorComposerConfig() {
    let eventService = this.intercomEventService;
    let analytics = {
      trackAnalyticsEvent: (eventName: any, attrs: any) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'article',
          place: 'composer',
          ...attrs,
        });
      },
    };

    let anchorLinkOnClick = (heading: any) => {
      this.copyHeadingLinkToClipboard(heading);
    };

    let config = new ArticlesComposerConfig(
      this.app,
      this.activeContent.textDirection,
      analytics,
      anchorLinkOnClick,
      true,
    );

    if (this.app.canUseArticlesAi) {
      config.formatters = {
        placement: 'right',
        config: [{ icon: 'ai', key: 'ai-expand' }].map(({ icon, key }) => ({
          icon,
          name: key,
          transform: async (selectedBlocks: any) => {
            this.notificationsService.notify(
              this.intl.t('articles.editor.ai-running'),
              ENV.APP._3000MS,
            );
            let blocks = await this.getSelectionForAI(selectedBlocks);
            return blocks;
          },
          description: this.intl.t(`articles.editor.${key}`),
        })),
      };
    }
    return config;
  }

  get activeContentBlocks() {
    if (this.activeContent && this.activeContent.jsonBlocks) {
      return this.activeContent.jsonBlocks;
    }
    return [];
  }

  @action
  prosemirrorComposerReady(composer: any) {
    this.prosemirrorComposer = composer;
  }

  @action
  prosemirrorComposerChanged(newBlocksDocument: BlocksDocument) {
    this.activeContent.set('jsonBlocks', newBlocksDocument.blocks);
  }

  copyHeadingLinkToClipboard(heading: any) {
    let url = `${this.activeContent.publicUrl}#${heading.idAttribute}`;
    let onSuccess = () => {
      this._trackCopyLink(heading, true);
      let previewText = htmlToTextContent(heading.text);
      previewText =
        previewText.length > MAX_PREVIEW_SIZE
          ? `${previewText.substring(0, MAX_PREVIEW_SIZE)}…`
          : previewText;
      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.editor.copied-anchor-link-to-place', { preview_text: previewText }),
      );
      this.clipboard?.destroy();
    };
    let onError = () => {
      this._trackCopyLink(heading, false);
      this.notificationsService.notifyWarning(
        this.intl.t('knowledge-hub.content-editor.article-content.clip-board-error'),
      );
      this.clipboard?.destroy();
    };
    this.clipboard = this.clipboardService.createClipboard(
      '.js__prosemirror-composer-copy-clipboard-element',
      url,
      onSuccess,
      onError,
    );
  }

  _trackCopyLink(heading: any, success: any) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'anchor_link_button',
      action: 'clicked',
      locale: this.currentlySelectedLocaleId,
      anchor_link_type: heading.type,
      success,
    });
  }

  get currentlySelectedLocaleId() {
    if (!this.activeContent) {
      return '';
    }
    return this.activeContent.locale;
  }

  async getSelectionForAI(selectedBlocks: any) {
    if (this.app.canUseArticlesAi) {
      let params = {
        entity_id: this.activeContent.id,
        entity_type: EntityType.ArticleContent,
        blocks: selectedBlocks,
        intercom_feature: 'article_composer',
      };

      try {
        let response = await post(
          `/ember/inbox/open_ai_demo/article_generator?app_id=${this.app.id}`,
          params,
        );

        return response.response_blocks;
      } catch (error) {
        this.notificationsService.notifyResponseError(error);
        throw error;
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::Edit::Editor': typeof Editor;
    'knowledge-hub/content-editor/article-content/edit/editor': typeof Editor;
  }
}
