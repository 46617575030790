/* import __COLOCATED_TEMPLATE__ from './update-author-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';

import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Admin from 'embercom/models/admin';
import { action } from '@ember/object';

interface Args {
  app: any;
  isBulkActionRunning?: boolean;
  isBulkSelection: boolean;
  isBulkAction: boolean;
  onClose: () => void;
  performBulkUpdateAuthor: (author: Admin) => Promise<void>;
}

export default class UpdateAuthorModal extends Component<Args> {
  @service declare appService: $TSFixMe;

  @tracked selectedAuthor = this.app.currentAdmin;

  get app() {
    return this.appService.app;
  }

  @action onAuthorSelect(author: Admin) {
    this.selectedAuthor = author;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::BulkActions::UpdateAuthorModal': typeof UpdateAuthorModal;
    'knowledge-hub/filterable-list/bulk-actions/update-author-modal': typeof UpdateAuthorModal;
  }
}
