/* import __COLOCATED_TEMPLATE__ from './chatbot-state.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { AiContentState } from 'embercom/lib/ai-content-library/constants';

interface Args {
  chatbotStates: AiContentState[];
}

export default class ChatbotState extends Component<Args> {
  stateWhenUsed = AiContentState.USED_BY_FIN;

  get hasStates(): boolean {
    return this.args.chatbotStates && this.args.chatbotStates.length > 0;
  }

  // returning true since there isn't any pre-condition for success state
  get preCondition(): boolean {
    return true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::ChatbotState': typeof ChatbotState;
    'knowledge-hub/filterable-list/table/cells/chatbot-state': typeof ChatbotState;
  }
}
