/* import __COLOCATED_TEMPLATE__ from './date.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnly from '@ember/component/template-only';

interface Args {
  date: Date;
}

const DateCell = templateOnly<Args>();

export default DateCell;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::Date': typeof DateCell;
    'knowledge-hub/filterable-list/table/cells/date': typeof DateCell;
  }
}
