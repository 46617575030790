/* import __COLOCATED_TEMPLATE__ from './collection-editor-modal.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type MediaHelper } from 'embercom/objects/media/media-helper';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import ArticleGroup from 'embercom/models/articles/article-group';
import ajax from 'embercom/lib/ajax';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    closeModal: () => void;
    eventSource: string;
    collection?: ArticleGroup;
    localeId?: string;
    onCreate?: (groupIds: string) => void;
    selectedHc?: string;
    parentCollection?: ArticleGroup;
    refreshData?: () => void;
    onNavigateBack?: () => void;
    inModalContext?: boolean;
  };
}

export default class CollectionEditorModal extends Component<Signature> {
  @tracked collection: ArticleGroup;
  @tracked externalIconMediaHelper?: MediaHelper;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: $TSFixMe;
  @service declare store: Store;

  constructor(owner: any, args: Signature['Args']) {
    super(owner, args);

    this.intercomEventService.trackAnalyticsEvent({
      action: this.args.parentCollection ? 'create_child_collection' : 'create_root_collection',
      object: 'collection_editor_modal',
      place: this.args.eventSource,
    });
    let helpCenterId = this.args.selectedHc || undefined;
    let parentCollection: ArticleGroup =
      this.args.parentCollection ?? this.store.peekRecord('articles/article-group', 'home');
    this.collection =
      this.args.collection ??
      ArticleGroup.createNewFolder(parentCollection, undefined, helpCenterId);

    if (!this.args.collection) {
      this.collection.name = '';
    }

    this.collection.initLocalizedContent();
  }

  get showIconSelector() {
    return !this.args.parentCollection;
  }

  get primaryButtonTranslationKey() {
    return this.args.collection
      ? 'knowledge-hub.content-editor.shared.collection-editor.save-collection'
      : 'knowledge-hub.content-editor.shared.collection-editor.create-collection';
  }

  get shouldDisableSaveCollectionButton() {
    return (
      !this.collection.selectedLocalizedContent?.name?.trim() ||
      !this.collection.selectedLocalizedContent?.description?.trim() ||
      !this.collection.icon
    );
  }

  @action
  async saveCollection() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'create_collection',
      object: 'collection_editor_modal',
      place: this.args.eventSource,
    });

    let collectionExternalIconUrl = this.collection.externalIconUrl;
    await this.collection.save();
    this.collection.externalIconUrl = collectionExternalIconUrl; // avoid blip in the icon preview while uploading

    try {
      let response = await this._saveExternalIconIfNeeded();
      if (response?.external_icon_url) {
        // makes sure the new external icon url is available to be used
        await this.collection.reload();
      }
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('articles.collections.external-icon.upload.error'),
      );
      this.externalIconMediaHelper?.setProperties({
        isSaved: false,
        isReset: true,
      });
      return;
    }

    if (this.args.onCreate) {
      this.args.onCreate(this.collection.id);
    }
    // it is important that we are calling the closeModal from args, not this component one since it rollback the attrs
    this.args.closeModal();

    if (this.args.refreshData) {
      this.args.refreshData();
    }
  }

  async _saveExternalIconIfNeeded() {
    if (this.externalIconMediaHelper) {
      let formData = new FormData();
      formData.append('asset_type', 'external_icon');
      formData.append('app_id', this.appService.app.id);
      formData.append('image', this.externalIconMediaHelper?.get('file'));
      let response = await ajax({
        type: 'POST',
        url: `/ember/article_folders/${this.collection.id}/upload_asset.json`,
        data: formData,
        contentType: false,
        processData: false,
      });

      return response;
    }
  }

  @action onNavigationBack() {
    if (
      this.args.onNavigateBack &&
      this.args.inModalContext &&
      this.appService.app.newHcSetupFlow
    ) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'navigate_back',
        object: 'collection_editor_modal',
        place: this.args.eventSource,
      });
      this.args.onNavigateBack();
    } else {
      this.closeModal();
    }
  }

  @action closeModal() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'canceled',
      object: 'collection_editor_modal',
      place: this.args.eventSource,
    });
    this.args.closeModal();
  }

  willDestroy(): void {
    this.collection.rollbackAttributes(); // making sure we clean the changes done inside the modal and not saved
    super.willDestroy();
  }

  @action onSelectIcon(mediaHelper: MediaHelper) {
    if (mediaHelper) {
      this.collection.externalIconUrl = mediaHelper.get('isReset') ? '' : mediaHelper.get('url'); // This is used to show the correct preview
      if (!this.externalIconMediaHelper) {
        this.externalIconMediaHelper = mediaHelper;
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::CollectionEditorModal': typeof CollectionEditorModal;
    'knowledge-hub/content-editor/shared/collection-editor-modal': typeof CollectionEditorModal;
  }
}
