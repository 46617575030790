/* import __COLOCATED_TEMPLATE__ from './guru.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type SyncSteps } from 'embercom/components/knowledge-hub/filterable-list';
import { type FolderTreeItem } from 'embercom/components/knowledge-hub/folder-selector';
import { post } from 'embercom/lib/ajax';
import { isValidEmail } from 'embercom/lib/email';
import type ImportSource from 'embercom/models/import-service/import-source';
import { SourceType, SyncBehaviorType } from 'embercom/models/import-service/import-source';
import type IntlService from 'embercom/services/intl';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

interface Args {
  modal: any;
  title?: string;
  onNavigationBack?: () => void;
  onNavigationNext?: (viewId: string) => void;
  showBackButton?: boolean;
  onModalClose: () => void;
  setSyncStep: (step: SyncSteps) => void;
}

type GuruCollection = {
  id: string;
  name: string;
};

export default class GuruSyncModal extends Component<Args> {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;

  @tracked importSource: ImportSource | undefined;
  @tracked selectedCollection?: string;

  @tracked hasCredentials = false;
  @tracked email = '';
  @tracked apiToken = '';
  @tracked credentialsSubmitted = false;
  @tracked collections: { text: string; value: string }[] = [];
  @tracked syncBehavior: SyncBehaviorType = SyncBehaviorType.Sync;
  @service declare appService: any;

  @tracked folders: FolderTreeItem[] = [];
  @tracked selectedFolderIds: Set<string> = new Set();

  get importSourceId() {
    return this.importSource?.id;
  }

  get submitDisabled() {
    return (
      (this.hasCredentials && !this.selectedCollection) ||
      !isValidEmail(this.email) ||
      this.apiToken.length === 0
    );
  }

  get isLoadingFolders() {
    return taskFor(this.fetchFolderSnapshot).isRunning;
  }

  get folderSelectorLabel() {
    if (this.isLoadingFolders) {
      return this.intl.t('knowledge-hub.sync-modal.folder-selector.loading-collection');
    }

    return undefined;
  }

  async startSync() {
    if (!this.selectedCollection) {
      return;
    }

    let collectionName = this.collections.find((c) => c.value === this.selectedCollection)?.text;
    let denyFolderIds: string[] = [];
    if (this.selectedFolderIds.size > 0) {
      denyFolderIds = this.folders
        .reject((folder) => this.selectedFolderIds.has(folder.id))
        .map((f) => f.id);
    }

    let importSourceParams = {
      sourceType: SourceType.Guru,
      email: this.email,
      authToken: this.apiToken,
      collectionId: this.selectedCollection,
      collectionName,
      denyFolderIds,
    };
    this.importSource = await this.knowledgeHubService.createImportSource(importSourceParams);

    this.knowledgeHubService.startImport(this.importSource.id, this.syncBehavior);
    this.args.onModalClose();

    let notificationMessage =
      this.syncBehavior === SyncBehaviorType.Sync
        ? this.intl.t('knowledge-hub.sync-modal.guru.sync-started')
        : this.intl.t('knowledge-hub.sync-modal.guru.import-started');

    this.knowledgeHubService.notifySourceStateChangeConfirmation(
      notificationMessage,
      this.importSource.id,
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'import-content',
      object: `import-content.guru`,
      section: 'knowledge-hub.new-content.guru',
      place: 'knowledge-hub.new-content',
    });
  }

  async fetchCollections(): Promise<GuruCollection[]> {
    let response = await post('/ember/import_service/import_sources/guru/collections', {
      app_id: this.appService.app.id,
      email: this.email,
      auth_token: this.apiToken,
    });

    let json = response as unknown as GuruCollection[];
    return json;
  }

  async fetchFolderTreeSnapshot(): Promise<FolderTreeItem[]> {
    let response = await post('/ember/import_service/import_sources/guru/folder_tree', {
      app_id: this.appService.app.id,
      email: this.email,
      auth_token: this.apiToken,
      collection_id: this.selectedCollection,
    });

    let json = response as unknown as FolderTreeItem[];
    return json;
  }

  @action
  async submit() {
    if (this.hasCredentials) {
      this.startSync();
    } else {
      await this.getAllCollections();
    }
  }

  async getAllCollections() {
    try {
      let collections = await this.fetchCollections();
      this.collections = collections.map((col) => ({ value: col.id, text: col.name }));
      this.hasCredentials = true;
    } catch (e) {
      let errorMessage = this.intl.t('knowledge-hub.sync-modal.guru.unknown-error');
      if (e.jqXHR?.responseJSON?.error_code === 'invalid_guru_credentials') {
        errorMessage = this.intl.t('knowledge-hub.sync-modal.guru.invalid-credentials');
      }
      this.notificationsService.notifyError(errorMessage);
    }
  }

  @action
  updateSelectedFolders(selectedFolderIds: Set<string>) {
    this.selectedFolderIds = selectedFolderIds;
  }

  @action
  selectCollection(collectionId: string) {
    this.selectedCollection = collectionId;

    taskFor(this.fetchFolderSnapshot).perform();
  }

  @task({ drop: true })
  *fetchFolderSnapshot(): TaskGenerator<void> {
    this.folders = yield this.fetchFolderTreeSnapshot();
    this.selectedFolderIds = new Set();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SyncModal::Guru': typeof GuruSyncModal;
    'knowledge-hub/setup-modal/sync-modal/guru': typeof GuruSyncModal;
  }
}
