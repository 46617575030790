/* RESPONSIBLE TEAM: team-product-setup */
import type IntlService from 'ember-intl/services/intl';

export function getCollectionPickerModalOptions(intl: IntlService) {
  return [
    {
      label: intl.t('articles.collections.collection_picker_modal.options.general.title'),
      description: intl.t(
        'articles.collections.collection_picker_modal.options.general.description',
      ),
      icon: 'ff-folder',
      id: 'general',
    },
    {
      label: intl.t('articles.collections.collection_picker_modal.options.faqs.title'),
      description: intl.t('articles.collections.collection_picker_modal.options.faqs.description'),
      icon: 'arr-sym-question-mark-circle',
      id: 'faqs',
    },
    {
      label: intl.t('articles.collections.collection_picker_modal.options.integrations.title'),
      description: intl.t(
        'articles.collections.collection_picker_modal.options.integrations.description',
      ),
      icon: 'sft-wrench-screwdriver',
      id: 'integrations',
    },
    {
      label: intl.t('articles.collections.collection_picker_modal.options.getting_started.title'),
      description: intl.t(
        'articles.collections.collection_picker_modal.options.getting_started.description',
      ),
      icon: 'arr-sym-play-circle',
      id: 'getting_started',
    },
    {
      label: intl.t('articles.collections.collection_picker_modal.options.troubleshooting.title'),
      description: intl.t(
        'articles.collections.collection_picker_modal.options.troubleshooting.description',
      ),
      icon: 'other-lifebuoy',
      id: 'troubleshooting',
    },
    {
      label: intl.t('articles.collections.collection_picker_modal.options.reports.title'),
      description: intl.t(
        'articles.collections.collection_picker_modal.options.reports.description',
      ),
      icon: 'charts-presentation-chart-bar',
      id: 'reports',
    },
    {
      label: intl.t('articles.collections.collection_picker_modal.options.billing.title'),
      description: intl.t(
        'articles.collections.collection_picker_modal.options.billing.description',
      ),
      icon: 'bizz-fin-banknotes',
      id: 'billing',
    },
    {
      label: intl.t('articles.collections.collection_picker_modal.options.payments.title'),
      description: intl.t(
        'articles.collections.collection_picker_modal.options.payments.description',
      ),
      icon: 'bizz-fin-credit-card',
      id: 'payments',
    },
    {
      label: intl.t('articles.collections.collection_picker_modal.options.settings.title'),
      description: intl.t(
        'articles.collections.collection_picker_modal.options.settings.description',
      ),
      icon: 'sft-cog-8-tooth',
      id: 'settings',
    },
    {
      label: intl.t('articles.collections.collection_picker_modal.options.account.title'),
      description: intl.t(
        'articles.collections.collection_picker_modal.options.account.description',
      ),
      icon: 'people-chat-gets-user-circle',
      id: 'account',
    },
    {
      label: intl.t('articles.collections.collection_picker_modal.options.security.title'),
      description: intl.t(
        'articles.collections.collection_picker_modal.options.security.description',
      ),
      icon: 'sft-lock-closed',
      id: 'security',
    },
    {
      label: intl.t('articles.collections.collection_picker_modal.options.release_notes.title'),
      description: intl.t(
        'articles.collections.collection_picker_modal.options.release_notes.description',
      ),
      icon: 'sft-megaphone',
      id: 'release_notes',
    },
  ];
}
