/* import __COLOCATED_TEMPLATE__ from './read-only.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import templateOnlyComponent from '@ember/component/template-only';

export interface Signature {
  Element: HTMLElement;
  Args: {
    tooltipKey: string;
  };
}

const ReadOnly = templateOnlyComponent<Signature>();
export default ReadOnly;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ReadOnly': typeof ReadOnly;
  }
}
