/* import __COLOCATED_TEMPLATE__ from './cancel-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  cancelEditActiveContent: () => void;
}

const CancelButton = templateOnlyComponent<Args>();
export default CancelButton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::Header::CancelButton': typeof CancelButton;
    'knowledge-hub/content-editor/shared/header/cancel-button': typeof CancelButton;
  }
}
