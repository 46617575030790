/* import __COLOCATED_TEMPLATE__ from './data-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type FileSourceContent from 'embercom/models/file-source-content';

interface Args {
  activeContent: FileSourceContent;
}

export default class DataSection extends Component<Args> {
  get content() {
    return this.args.activeContent;
  }

  @action async setLocale(languageCode: string): Promise<void> {
    await this.content.updateLanguageCode(languageCode);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Pdf::DataSection': typeof DataSection;
    'knowledge-hub/content-editor/pdf/data-section': typeof DataSection;
  }
}
