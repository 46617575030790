/* import __COLOCATED_TEMPLATE__ from './written-by.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type Admin from 'embercom/models/admin';
import { inject as service } from '@ember/service';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';

interface Args {
  activeContent: LocalizedKnowledgeContent;
}

export default class WrittenBy extends Component<Args> {
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;

  @action
  changeWriter(writer: Admin) {
    this.args.activeContent.writer = writer;
    this.knowledgeHubEditorService.trackAnalyticsEvent('changed_written_by');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::DataSection::WrittenBy': typeof WrittenBy;
    'knowledge-hub/content-editor/shared/data-section/written-by': typeof WrittenBy;
  }
}
