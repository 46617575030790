/* import __COLOCATED_TEMPLATE__ from './discovery-banner.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { ContentFilter } from '../overview';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type Router from '@ember/routing/router-service';
import type AiAgentSetupService from 'embercom/services/ai-agent-setup-service';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';

interface Args {
  contentFilter: ContentFilter;
  hasLiveHelpCenter: boolean;
  helpCenterSettingsUrl: string;
}

export type SourceCardConfig = {
  source: ContentFilter;
  title: string;
  description: string;
  ctaLabel: string;
  ctaAction: () => void;
  isLive: boolean;
  imageUrl: string;
};

const BANNER_IMAGE_URLS = {
  [ContentFilter.All]: assetUrl('/assets/images/knowledge-hub/overview/all.png'),
  [ContentFilter.AiAgent]: assetUrl('/assets/images/knowledge-hub/overview/ai-agent.png'),
  [ContentFilter.AiCopilot]: assetUrl('/assets/images/knowledge-hub/overview/ai-copilot.png'),
  [ContentFilter.HelpCenter]: assetUrl('/assets/images/knowledge-hub/overview/help-center.png'),
};

const TITLE_KEYS = {
  [ContentFilter.All]: 'knowledge-hub.overview.discovery-banner.heading.all',
  [ContentFilter.AiAgent]: 'knowledge-hub.overview.new-discovery-banner.heading.ai-agent',
  [ContentFilter.AiCopilot]: 'knowledge-hub.overview.new-discovery-banner.heading.ai-copilot',
  [ContentFilter.HelpCenter]: 'knowledge-hub.overview.new-discovery-banner.heading.help-center',
};
const DESCRIPTION_KEYS = {
  [ContentFilter.All]: 'knowledge-hub.overview.discovery-banner.description.all',
  [ContentFilter.AiAgent]: 'knowledge-hub.overview.new-discovery-banner.description.ai-agent',
  [ContentFilter.AiCopilot]: 'knowledge-hub.overview.new-discovery-banner.description.ai-copilot',
  [ContentFilter.HelpCenter]: 'knowledge-hub.overview.new-discovery-banner.description.help-center',
};

export default class DiscoveryBanner extends Component<Args> {
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare appService: $TSFixMe;
  @service declare aiAgentSetupService: AiAgentSetupService;
  @service declare intercomEventService: $TSFixMe;
  @service declare helpCenterService: $TSFixMe;

  get learnMoreArticleId() {
    switch (this.args.contentFilter) {
      case ContentFilter.AiAgent:
        return 7120684;
      case ContentFilter.AiCopilot:
        return 9121374;
      case ContentFilter.HelpCenter:
        return 56640;
      // default to Knowledge Hub explained article
      default:
        return 9357912;
    }
  }

  get title() {
    return this.intl.t(TITLE_KEYS[this.args.contentFilter]);
  }

  get description() {
    return this.intl.t(DESCRIPTION_KEYS[this.args.contentFilter]);
  }

  get hasSingleSite() {
    return this.helpCenterService.sites?.length === 1 && !!this.helpCenterService.site;
  }

  get sourceCardConfigs(): SourceCardConfig[] {
    let SET_UP_NOW = this.intl.t('knowledge-hub.overview.button-labels.set-up-now');
    let GO_TO_INBOX = this.intl.t('knowledge-hub.overview.button-labels.go-to-inbox');
    let GO_TO_SETTINGS = this.intl.t('knowledge-hub.overview.button-labels.go-to-settings');
    let GO_TO_AI_AND_AUTOMATION = this.intl.t('knowledge-hub.overview.button-labels.go-to-ai-page');

    return [
      {
        source: ContentFilter.AiAgent,
        title: this.intl.t('knowledge-hub.overview.source-card.title.ai-agent'),
        description: this.intl.t('knowledge-hub.overview.source-card.description.ai-agent'),
        ctaAction: () => {
          this.trackAnalyticsEvent(ContentFilter.AiAgent);
          this.router.transitionTo('apps.app.fin-ai-agent.setup');
        },
        isLive: this.aiAgentEnabled,
        ctaLabel: this.aiAgentEnabled ? GO_TO_AI_AND_AUTOMATION : SET_UP_NOW,
        imageUrl: assetUrl('/assets/images/knowledge-hub/overview/source-cards/ai-agent.png'),
      },
      {
        source: ContentFilter.AiCopilot,
        title: this.intl.t('knowledge-hub.overview.source-card.title.ai-copilot'),
        description: this.intl.t('knowledge-hub.overview.source-card.description.ai-copilot'),
        isLive: this.copilotEnabled,
        ctaLabel: GO_TO_INBOX,
        imageUrl: assetUrl('/assets/images/knowledge-hub/overview/source-cards/ai-copilot.png'),
        ctaAction: () => {
          this.trackAnalyticsEvent(ContentFilter.AiCopilot);
          this.router.transitionTo(
            'inbox.workspace.inbox.inbox',
            this.appService.app.id,
            InboxCategory.Shared,
            InboxType.All,
            {
              queryParams: {
                open_copilot: true,
              },
            },
          );
        },
      },
      {
        source: ContentFilter.HelpCenter,
        title: this.intl.t('knowledge-hub.overview.source-card.title.help-center'),
        description: this.intl.t('knowledge-hub.overview.source-card.description.help-center'),
        ctaAction: () => {
          this.trackAnalyticsEvent(ContentFilter.HelpCenter);
          this.hasSingleSite
            ? this.router.transitionTo(
                'apps.app.settings.helpcenter.workspace-helpcenter.collections',
                this.appService.app,
                this.helpCenterService.site,
              )
            : this.router.transitionTo('apps.app.settings.helpcenter.all');
        },
        isLive: this.args.hasLiveHelpCenter,
        ctaLabel: this.args.hasLiveHelpCenter ? GO_TO_SETTINGS : SET_UP_NOW,
        imageUrl: assetUrl('/assets/images/knowledge-hub/overview/source-cards/help-center.png'),
      },
    ];
  }

  @action
  trackAnalyticsEvent(section: ContentFilter) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'discovery_banner',
      place: `knowledge_hub_overview_${this.args.contentFilter.replaceAll('-', '_')}`, // which tab was clicked
      section: section.replaceAll('-', '_'),
    });
  }

  get copilotEnabled() {
    return true;
  }

  get aiAgentEnabled() {
    return this.aiAgentSetupService.isFinLive;
  }

  get bannerConfig() {
    return (
      this.sourceCardConfigs.find((config) => config.source === this.args.contentFilter) ||
      this.sourceCardConfigs[0]
    );
  }

  get ctaLabel() {
    return this.bannerConfig.ctaLabel;
  }

  get ctaAction() {
    return this.bannerConfig.ctaAction;
  }

  get isLive() {
    return this.bannerConfig.isLive;
  }

  get bannerImage() {
    return BANNER_IMAGE_URLS[this.args.contentFilter];
  }

  @action openHelpCenter() {
    safeWindowOpen(this.args.helpCenterSettingsUrl);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::DiscoveryBanner': typeof DiscoveryBanner;
    'knowledge-hub/overview/discovery-banner': typeof DiscoveryBanner;
  }
}
