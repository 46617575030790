/* import __COLOCATED_TEMPLATE__ from './help-center-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import templateOnlyComponent from '@ember/component/template-only';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  helpCenterSites: HelpCenterSite[];
  selectedHelpCenterIds?: Array<string>;
  doSearch: (helpCenterIds?: Array<string>) => void;
  removeFilter?: (status?: string) => void;
  activeFilter?: string;
}

const HelpCenterFilter = templateOnlyComponent<Args>();
export default HelpCenterFilter;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::HelpCenterFilter': typeof HelpCenterFilter;
    'knowledge-hub/filterable-list/filters/help-center-filter': typeof HelpCenterFilter;
  }
}
