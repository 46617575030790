/* import __COLOCATED_TEMPLATE__ from './title.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type App from 'embercom/app';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  activeContent: LocalizedKnowledgeContent;
}

export default class Editor extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get app(): App {
    return this.appService.app;
  }

  get entityType() {
    return this.args.activeContent.entityType;
  }

  get typeTitle() {
    if (this.args.activeContent.defaultTitle) {
      return this.args.activeContent.defaultTitle;
    }
    return this.intl.t(`knowledge-hub.content-type-stamp.content-type.${this.entityType}`);
  }

  get currentTitle() {
    if (
      this.args.activeContent.latestVersion?.versionTitle === this.args.activeContent.defaultTitle
    ) {
      return '';
    }
    return this.args.activeContent.latestVersion?.versionTitle;
  }

  set currentTitle(value: string | undefined) {
    if (!this.args.activeContent.latestVersion) {
      return;
    }
    this.args.activeContent.latestVersion.versionTitle = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::Edit::Title': typeof Editor;
    'knowledge-hub/content-editor/shared/edit/title': typeof Editor;
  }
}
