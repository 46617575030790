/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type App from 'embercom/app';
import type FileSourceContent from 'embercom/models/file-source-content';

interface Args {
  activeContent: FileSourceContent;
  defaultThemeColor: string;
}

export default class Editor extends Component<Args> {
  @service declare appService: $TSFixMe;

  get app(): App {
    return this.appService.app;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Pdf::View::Editor': typeof Editor;
    'knowledge-hub/content-editor/pdf/view/editor': typeof Editor;
  }
}
