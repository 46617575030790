/* import __COLOCATED_TEMPLATE__ from './websites.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { EntityType } from 'embercom/models/data/entity-types';
import { type OverviewSectionArgs } from '../../overview';

export default class Websites extends Component<OverviewSectionArgs> {
  entityType = EntityType.ExternalContent;

  @action addSyncSource() {
    this.args.onAddSource('external_content');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Sections::Websites': typeof Websites;
    'knowledge-hub/overview/sections/Websites': typeof Websites;
  }
}
