/* import __COLOCATED_TEMPLATE__ from './updated-at.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  updatedAt: string | Date;
}

const UpdatedAt = templateOnlyComponent<Args>();
export default UpdatedAt;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::DataSection::UpdatedAt': typeof UpdatedAt;
    'knowledge-hub/content-editor/shared/data-section/updated-at': typeof UpdatedAt;
  }
}
