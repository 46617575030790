/* import __COLOCATED_TEMPLATE__ from './bulk-tagging-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import type Tag from 'embercom/models/tag';
import { tracked } from '@glimmer/tracking';
import escapeRegExp from 'embercom/lib/escape-regular-expression';
import type Store from '@ember-data/store';
import { type Router } from '@ember/routing';
import type KnowledgeHubContentWrapper from 'embercom/models/content-service/knowledge-hub-content-wrapper';

type RenderableTag = {
  id: string;
  name: string;
  selected: boolean;
};

interface Args {
  contents: KnowledgeHubContentWrapper[];
  title: string;
  isBulkActionRunning: boolean;
  type?: string;
  performBulkTag: (tagsToApply?: Tag[], tagsToRemove?: Tag[]) => void;
  onClose?: () => void;
}

interface Signature {
  Args: Args;
}

export default class BulkTaggingModal extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare store: Store;
  @service declare router: Router;
  @service declare intercomEventService: any;

  @tracked highlightIndex = -1;
  @tracked query = '';
  @tracked allTags: RenderableTag[];
  // to diff against when applying the changes
  @tracked initialTagList: RenderableTag[];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.allTags = this.initTagsList();
    this.initialTagList = this.initTagsList();
  }

  private initTagsList() {
    let renderableTags = this.tags.map((tag: Tag) => {
      return {
        id: tag.id,
        name: tag.name,
        selected: this.commonSelectedTagIds.includes(tag.id) || false,
      };
    });

    return this.orderTags(renderableTags);
  }

  private orderTags(tags: RenderableTag[]) {
    return tags.sort((a: RenderableTag, b: RenderableTag) => {
      if (a.selected !== b.selected) {
        return b.selected ? 1 : -1;
      }
      return a.name.localeCompare(b.name);
    });
  }

  get commonSelectedTagIds() {
    if (!this.args.contents || this.args.contents.length === 0) {
      return [];
    }

    let commonTagIds = new Set(this.args.contents[0].tags.map((tag: Tag) => tag.id) || []);

    for (let i = 1; i < this.args.contents.length; i++) {
      let currentTagIds = new Set(this.args.contents[i].tags.map((tag: Tag) => tag.id) || []);

      commonTagIds = new Set([...commonTagIds].filter((id) => currentTagIds.has(id)));

      if (commonTagIds.size === 0) {
        break;
      }
    }

    return Array.from(commonTagIds);
  }

  get app() {
    return this.appService.app;
  }

  get tags() {
    return this.app.tags;
  }

  get listOfTags() {
    if (this.query) {
      return this.matchingTags;
    } else {
      return this.allTags;
    }
  }

  get matchingTags() {
    let query = this.query.trim();
    let escaped = escapeRegExp(query);
    let regex = new RegExp(escaped, 'i');
    return this.allTags.filter((tag) => {
      return regex.test(tag.name);
    });
  }

  get allowTagManagement() {
    return this.app.currentAdmin.canManageTags;
  }

  get createNewTagVisible() {
    if (!this.allowTagManagement) {
      return false;
    }
    if (!this.query) {
      return false;
    }

    let query = this.query.toLowerCase();

    return !this.app.tags.any((tag: Tag) => {
      return query === tag.get('name').toLowerCase();
    });
  }

  get createTagLabel() {
    return this.intl.t('knowledge-hub.content-editor.shared.tags.modal.create-tag', {
      tagName: this.query,
    });
  }

  @action
  updateQuery(query: string) {
    this.query = query;
  }

  @action selectTag(tag: { id: string; name: string; selected: boolean }) {
    if (tag.selected) {
      let t = this.tags.findBy('id', tag.id);
      this.updateSelectedTags(undefined, t);
    } else {
      let t = this.tags.findBy('id', tag.id);
      this.updateSelectedTags(t, undefined);
    }
    this.resetQuery();
  }

  @action resetQuery() {
    if (this.query !== '') {
      this.allTags = this.orderTags(this.allTags);
      this.updateQuery('');
    }
  }

  @action applyTags() {
    let [addedTags, removedTags] = this.calculateTagChanges();
    this.args.performBulkTag(addedTags, removedTags);
    this.trackTag();
  }

  @action handleKeyDown(event: KeyboardEvent) {
    if (this.createNewTagVisible && event.key === 'Enter') {
      this.createAndSelectTag();
    }
  }

  private calculateTagChanges() {
    let addedTags: Tag[] = [];
    let removedTags: Tag[] = [];
    let initiallySelectedTags = this.initialTagList.filter((tag) => tag.selected);

    this.allTags.forEach((tag: RenderableTag) => {
      let tagObject = this.tags.findBy('id', tag.id);
      if (tag.selected && !initiallySelectedTags.find((t) => t.id === tag.id)) {
        addedTags.push(tagObject);
      } else if (!tag.selected && initiallySelectedTags.find((t) => t.id === tag.id)) {
        removedTags.push(tagObject);
      }
    });

    return [addedTags, removedTags];
  }

  private updateSelectedTags(addedTag?: Tag, removedTag?: Tag) {
    this.allTags = this.allTags.map((tag: RenderableTag) => {
      if (tag.id === addedTag?.id) {
        return {
          ...tag,
          selected: true,
        };
      } else if (tag.id === removedTag?.id) {
        return {
          ...tag,
          selected: false,
        };
      } else {
        return tag;
      }
    });
  }

  @action async createAndSelectTag() {
    let newTag = await this.store.createRecord('tag', {
      name: this.query,
      app_id: this.app.id,
    });
    await newTag.save();
    this.app.tags.pushObject(newTag);
    this.allTags.push({
      id: newTag.id,
      name: newTag.name,
      selected: true,
    });
    this.resetQuery();
  }

  private trackTag() {
    this.intercomEventService.trackEvent('bulk-tagged', {
      action: 'tagged',
      object: 'bulk',
      place: 'knowledge-hub',
      message_id: this.args.contents.map((content) => content.id),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::Tags::BulkTaggingModal': typeof BulkTaggingModal;
    'knowledge-hub/content-editor/shared/tags/bulk-tagging-modal': typeof BulkTaggingModal;
  }
}
