/* import __COLOCATED_TEMPLATE__ from './tile.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import templateOnlyComponent from '@ember/component/template-only';
import type { EntityType } from 'embercom/models/data/entity-types';

interface Signature {
  id: string;
  title: string;
  description?: string;
  iconOnlyEntityType?: boolean;
  entityType: EntityType;
  onClick: () => void;
  sourceIconUrl?: string;
  titleSize: '1' | '2' | '3' | '4';
}

const Tile = templateOnlyComponent<Signature>();
export default Tile;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::SetupModal::Tile': typeof Tile;
    'knowledge-hub/filterable-list/setup-modal/tile': typeof Tile;
  }
}
