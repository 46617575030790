/* import __COLOCATED_TEMPLATE__ from './last-updated-by.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  lastUpdatedBy?: string;
  doSearch: (lastUpdatedBy?: string) => void;
  activeFilter?: string;
  removeFilter: () => void;
}

export default class LastUpdatedBy extends Component<Args> {
  @service declare appService: any;
  @service intl!: IntlService;

  get app(): any {
    return this.appService.app;
  }

  get selectedAdmin(): any {
    return this.admins.find((admin: any) => admin.value === this.args.lastUpdatedBy);
  }

  get selectedName() {
    return this.selectedAdmin?.text;
  }

  get isOpen() {
    return this.args.activeFilter === 'lastUpdatedBy' && !this.args.lastUpdatedBy;
  }

  get admins() {
    let admins: any[] = [this.app.operatorBot, ...this.app.humanAdmins];

    return admins.flat().map((admin: any) => {
      return {
        text: admin.name,
        value: admin.id,
        avatarData: admin.avatarData,
        component: 'common/avatar-and-name-dropdown-item',
        componentAttrs: {
          model: admin,
          modelDisplayName: admin.name,
          avatarSize: 'xs',
        },
      };
    });
  }

  @action removeFilter() {
    this.args.removeFilter();
    this.args.doSearch();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::LastUpdatedBy': typeof LastUpdatedBy;
    'knowledge-hub/filterable-list/filters/last-updated-by': typeof LastUpdatedBy;
  }
}
