/* import __COLOCATED_TEMPLATE__ from './publish-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import type ArticleContent from 'embercom/models/articles/article-content';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import { MULTILINGUAL_ARTICLES_FEATURE, ARTICLES_FEATURE } from 'embercom/lib/billing';

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export interface Args {
  activeContent: ArticleContent;
  publishActiveContent: () => Promise<void>;
  label?: string;
  inRightList?: boolean;
  disabled?: boolean;
}

export default class PublishButton extends Component<Signature> {
  @service declare permissionsService: any;

  @tracked showHelpCenterModal = false;
  @tracked isPublishing = false;

  get article() {
    return this.args.activeContent.article;
  }

  get paywallConfig() {
    let configs = this.args.activeContent.editorConfig.paywallConfig;
    if (configs) {
      if (!this.args.activeContent.isDefaultActiveContent) {
        return configs.find((config) => config.featureKey === MULTILINGUAL_ARTICLES_FEATURE);
      }
      return configs.find((config) => config.featureKey === ARTICLES_FEATURE);
    }
    return undefined;
  }

  @action async publishContent() {
    try {
      await this.permissionsService.checkPermission(
        this.args.activeContent.requiredPublishPermissionForKnowledgeHub,
      );
    } catch (e) {
      return;
    }

    if (this.article?.inCollections.length === 0 && this.args.activeContent.isNotPublished) {
      this.toggleHelpCenterModal(true);
      return;
    }

    await this.publishActiveContent();
  }

  @action async publishActiveContent() {
    this.isPublishing = true;
    await this.args.publishActiveContent();
    this.isPublishing = false;
  }

  @action toggleHelpCenterModal(state = false): void {
    this.showHelpCenterModal = state;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::PublishButton': typeof PublishButton;
    'knowledge-hub/content-editor/article-content/publish-button': typeof PublishButton;
  }
}
