/* import __COLOCATED_TEMPLATE__ from './confluence.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type ConfluenceSite, type ConfluenceSpace } from 'embercom/services/knowledge-hub-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { taskFor } from 'ember-concurrency-ts';
import type ImportSource from 'embercom/models/import-service/import-source';
import type IntlService from 'embercom/services/intl';
import { SyncSteps } from 'embercom/components/knowledge-hub/filterable-list';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { SourceType, SyncBehaviorType } from 'embercom/models/import-service/import-source';
import { type FolderTreeItem } from 'embercom/components/knowledge-hub/folder-selector';
import { get } from 'embercom/lib/ajax';

interface Args {
  modal: any;
  title?: string;
  onNavigationBack?: () => void;
  onNavigationNext?: (viewId?: string) => void;
  showBackButton?: boolean;
  onModalClose: () => void;
  setSyncStep: (step: SyncSteps) => void;
}

export default class ConfluenceSyncModal extends Component<Args> {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare openCenteredWindowService: any;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare appService: any;
  @service declare intercomEventService: any;

  @tracked importSource: ImportSource | undefined;
  @tracked selectedSite: ConfluenceSite | undefined;
  @tracked selectedSpace: ConfluenceSpace | undefined;
  @tracked sites: ConfluenceSite[] = [];
  @tracked spaces: ConfluenceSpace[] = [];

  @tracked folders: FolderTreeItem[] = [];
  @tracked selectedFolderIds: Set<string> = new Set();
  @tracked syncBehavior: SyncBehaviorType = SyncBehaviorType.Sync;

  get isLoadingOauth() {
    return taskFor(this.startConfluenceAuth).isRunning;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    taskFor(this.startConfluenceAuth).perform();
  }

  get selectedSiteId() {
    return this.selectedSite?.id;
  }

  get selectedSpaceId() {
    return this.selectedSpace?.id;
  }

  get importSourceId() {
    return this.importSource?.id;
  }

  get sitesGroupList() {
    return [
      {
        items: this.sites.map((site: ConfluenceSpace) => ({
          text: site.name,
          value: site.id,
        })),
      },
    ];
  }

  get selectedSiteLabel() {
    let selectedSite = this.sites.findBy('id', this.selectedSiteId);
    if (!selectedSite) {
      return this.intl.t('knowledge-hub.sync-modal.confluence.select-site-dropdown');
    }

    return selectedSite.name;
  }

  @action
  selectSite(siteId: string) {
    this.selectedSite = this.sites.findBy('id', siteId);
    this.resetSpaceSelection();

    this.loadSpaces();
  }

  get selectedSpaceLabel() {
    if (this.isLoadingSpaces) {
      return this.intl.t('knowledge-hub.sync-modal.confluence.load-space-label');
    }

    if (!this.selectedSpace) {
      return this.intl.t('knowledge-hub.sync-modal.confluence.select-space-dropdown');
    }

    return this.selectedSpace.name;
  }

  get spacesGroupList() {
    return [
      {
        items: this.spaces.map((space: ConfluenceSpace) => ({
          text: space.name,
          value: space.id,
        })),
      },
    ];
  }

  @action
  selectSpace(spaceId: string) {
    this.selectedSpace = this.spaces.findBy('id', spaceId);

    taskFor(this.fetchFolderSnapshot).perform();
    this.resetFolderSelection();
  }

  get loadSpacesButtonDisabled() {
    return !this.importSourceId || !this.selectedSiteId;
  }

  @action
  async loadSpaces() {
    if (!this.importSourceId || !this.selectedSiteId) {
      return;
    }

    this.spaces = await taskFor(this.knowledgeHubService.fetchSpacesForSite).perform(
      this.importSourceId,
      this.selectedSiteId,
    );
  }

  get isLoadingSpaces() {
    return taskFor(this.knowledgeHubService.fetchSpacesForSite).isRunning;
  }

  get syncDisabled() {
    return !this.importSourceId || !this.selectedSiteId || !this.selectedSpaceId;
  }

  @task({ drop: true })
  *startConfluenceAuth(): TaskGenerator<void> {
    let newSource = yield this.knowledgeHubService.createImportSource({
      sourceType: SourceType.Confluence,
    });
    this.importSource = newSource;

    let url = this.knowledgeHubService.getOAuthURL(newSource.id);

    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    let window = this.openCenteredWindowService.setup(url, 800, 1280, 'Import');
    if (window) {
      window.focus();
      yield this.waitForWindowClose(window);
    }

    try {
      this.sites = yield taskFor(this.knowledgeHubService.fetchSites).perform(newSource.id);
      if (this.sites.length === 1) {
        this.selectSite(this.sites[0].id);
      }
    } catch (error) {
      this.args.onNavigationBack?.();
      this.args.setSyncStep?.(SyncSteps.Connect);
      yield newSource.remove({ notify: false });
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.sync-modal.confluence.generic-error'),
      );
    }
  }

  private waitForWindowClose(window: any): Promise<void> {
    return new Promise((resolve) => {
      let checkInterval = setInterval(() => {
        if (window.closed) {
          clearInterval(checkInterval);
          resolve();
        }
      }, 200);
    });
  }

  @action
  async startSync() {
    if (!this.importSource || !this.selectedSiteId || !this.selectedSpace) {
      return;
    }

    // Update confluence import context
    let source = this.importSource;
    source.siteId = this.selectedSiteId;
    source.spaceId = this.selectedSpace.id;
    source.spaceName = this.selectedSpace.name;

    let denyFolderIds: string[] = [];
    if (this.selectedFolderIds.size > 0) {
      denyFolderIds = this.folders
        .reject((folder) => this.selectedFolderIds.has(folder.id))
        .map((f) => f.id);
      source.denyFolderIds = denyFolderIds;
    }

    if (this.selectedSite?.url) {
      source.baseUrl = this.selectedSite?.url;
    }
    await source.save();

    this.knowledgeHubService.startImport(this.importSource.id, this.syncBehavior);
    this.args.onModalClose();

    let notificationMessage =
      this.syncBehavior === SyncBehaviorType.Sync
        ? this.intl.t('knowledge-hub.sync-modal.confluence.sync-started')
        : this.intl.t('knowledge-hub.sync-modal.confluence.import-started');

    this.knowledgeHubService.notifySourceStateChangeConfirmation(
      notificationMessage,
      this.importSource.id,
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'import-content',
      object: `import-content.confluence`,
      section: 'knowledge-hub.new-content.confluence',
      place: 'knowledge-hub.new-content',
    });
  }

  @action
  updateSelectedFolders(selectedFolderIds: Set<string>) {
    this.selectedFolderIds = selectedFolderIds;
  }

  @task({ drop: true })
  *fetchFolderSnapshot(): TaskGenerator<void> {
    if (!this.importSourceId || !this.selectedSiteId || !this.selectedSpaceId) {
      return;
    }

    this.folders = yield this.fetchFolderTreeSnapshot();
  }

  async fetchFolderTreeSnapshot(): Promise<FolderTreeItem[]> {
    let response = await get('/ember/import_service/import_sources/confluence/folder_tree', {
      app_id: this.appService.app.id,
      import_source_id: this.importSourceId,
      site_id: this.selectedSiteId,
      space_id: this.selectedSpaceId,
    });

    let json = response as unknown as FolderTreeItem[];
    return json;
  }

  get isLoadingFolders() {
    return taskFor(this.fetchFolderSnapshot).isRunning;
  }

  get folderSelectorLabel() {
    if (this.isLoadingFolders) {
      return this.intl.t('knowledge-hub.sync-modal.folder-selector.loading-collection');
    }

    return undefined;
  }

  private resetSpaceSelection() {
    this.spaces = [];
    this.selectedSpace = undefined;
    this.resetFolderSelection();
  }

  private resetFolderSelection() {
    this.folders = [];
    this.selectedFolderIds = new Set();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SyncModal::Confluence': typeof ConfluenceSyncModal;
    'knowledge-hub/setup-modal/sync-modal/confluence': typeof ConfluenceSyncModal;
  }
}
