/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { get } from '@ember/object';
import Component from '@glimmer/component';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';

interface Args {
  activeContent: ContentSnippet;
  showSidePanel: boolean;
  cancelEditActiveContent: () => void;
  saveActiveContent: () => void;
  publishActiveContent: () => void;
  toggleSidePanel: () => void;
}

export default class Header extends Component<Args> {
  get saving(): boolean {
    return !!get(this.args.activeContent, 'isSaving');
  }

  set saving(_value) {
    // noop
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Snippet::Edit::Header': typeof Header;
    'knowledge-hub/content-editor/snippet/edit/header': typeof Header;
  }
}
