/* import __COLOCATED_TEMPLATE__ from './copilot-coming-soon.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ContentImportService from 'embercom/services/content-import-service';
import { action } from '@ember/object';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';

interface Args {}

const KNOWLEDGE_HUB_COPILOT_COMING_SOON_BANNER_DISMISSED =
  'knowledge-hub-copilot-coming-soon-banner-dismissed';

const BANNER_DISMISSED_VALUE = 'true';
export default class CopilotComingSoon extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare contentImportService: ContentImportService;

  @trackedInLocalStorage({
    keyName: KNOWLEDGE_HUB_COPILOT_COMING_SOON_BANNER_DISMISSED,
  })
  hasDismissedBanner: string | undefined;

  get app() {
    return this.appService.app;
  }

  get bannerDismissed() {
    return this.hasDismissedBanner === BANNER_DISMISSED_VALUE;
  }

  get showBanner(): boolean {
    return !this.app.canUseFeature('answerbot-inbox-copilot') && !this.bannerDismissed;
  }

  @action hideBanner() {
    this.hasDismissedBanner = BANNER_DISMISSED_VALUE;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Banners::CopilotComingSoon': typeof CopilotComingSoon;
  }
}
