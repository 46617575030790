/* import __COLOCATED_TEMPLATE__ from './locale.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { capitalize } from '@ember/string';
import { MAX_LOCALES_SHOWN_IN_TABLE_CELL } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  locales: string[];
}

export default class Locale extends Component<Args> {
  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };

  get locales(): string[] {
    if (this.args.locales.length === 1) {
      return this.args.locales.map((locale) => this.intl.languageNameFromCode(locale));
    } else {
      return this.capitalizedLocales();
    }
  }

  get overflownLocalesText(): string {
    // returns '+ n more' if num locales > than the max that can be shown
    if (this.args.locales.length > MAX_LOCALES_SHOWN_IN_TABLE_CELL) {
      return this.intl.t(`knowledge-hub.filterable-list.list.cells.locale.overflow`, {
        numOverflowLocales: this.args.locales.length - MAX_LOCALES_SHOWN_IN_TABLE_CELL + 1,
      });
    } else {
      return '';
    }
  }

  private capitalizedLocales(): string[] {
    let locales = this.args.locales;
    if (locales.length > MAX_LOCALES_SHOWN_IN_TABLE_CELL) {
      locales = locales.slice(0, MAX_LOCALES_SHOWN_IN_TABLE_CELL - 1);
    }
    return locales.map((locale) => capitalize(locale));
  }

  get isLocaleToShow() {
    return this.args.locales.length > 0 && this.args.locales[0] !== '' && this.args.locales[0];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::Locale': typeof Locale;
    'knowledge-hub/filterable-list/table/cells/locale': typeof Locale;
  }
}
