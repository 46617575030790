/* import __COLOCATED_TEMPLATE__ from './conversations.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { type OverviewSectionArgs } from '../../overview';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { tracked } from 'tracked-built-ins';
import type CopilotApi from 'embercom/services/copilot-api';
import type IntlService from 'ember-intl/services/intl';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

export default class Conversations extends Component<OverviewSectionArgs> {
  @service declare router: RouterService;
  @service declare appService: any;
  @service declare copilotApi: CopilotApi;
  @tracked showManageConversationsDrawer = this.args.showManageConversationsDrawer ?? false;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;
  UNASSIGNED_ADMIN_ID = 0;

  @action async onManageConversations() {
    try {
      await this.knowledgeHubService.ensurePermissions();
    } catch (e) {
      return;
    }
    this.copilotApi.refreshCopilotSettingsData();
    this.showManageConversationsDrawer = true;
  }

  @action
  closeDrawer() {
    this.copilotApi.refreshCopilotSettingsData();
    this.showManageConversationsDrawer = false;
    this.router.transitionTo(this.router.currentRouteName, {
      queryParams: { manageConversations: false },
    });
  }

  @action goToTicketImports() {
    this.router.transitionTo('apps.app.settings.data.imports-exports');
  }

  @action goToConversations() {
    this.router.transitionTo('apps.app.inbox', {
      queryParams: { open_copilot: true },
    });
  }

  get enabledAdmins() {
    return this.copilotApi.copilotSettings?.trusted_admin_ids ?? [];
  }

  get excerptsEnabled() {
    return this.copilotApi.copilotSettings?.use_past_conversations ?? true;
  }

  get conversationAttributes() {
    return this.copilotApi.copilotSettings?.excerpt_matching_attributes;
  }

  get allAdmins() {
    return this.appService.app.humanAdmins.map(
      (admin: { id: number; name: string; role?: string }) => ({
        id: Number(admin.id),
        name: admin.name,
        role: admin.role,
      }),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Sections::Conversations': typeof Conversations;
    'knowledge-hub/overview/sections/conversations': typeof Conversations;
  }
}
