/* import __COLOCATED_TEMPLATE__ from './audience-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';

import { inject as service } from '@ember/service';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import { action } from '@ember/object';
import type AiContentSegment from 'embercom/models/ai-content-segment';
import { tracked } from '@glimmer/tracking';
import { helper } from '@ember/component/helper';
import type ContentFragment from 'embercom/models/content-service/content-fragment';
import { type AudienceBulkActionApiParams } from 'embercom/components/knowledge-hub/filterable-list/bulk-actions/bulk-action-helper';
// @ts-ignore
import { calculateMaxHeight } from '@intercom/pulse/lib/dropdown-utils';
// @ts-ignore
import trustedStyle from '@intercom/pulse/lib/trusted-style';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type RouterService from '@ember/routing/router-service';

interface Args {
  item: {
    selectedFragments: Set<ContentFragment>;
    applyAudience: (audienceBulkActionApiParams: AudienceBulkActionApiParams) => Promise<void>;
  };
}

export default class AudienceDropdown extends Component<Args> {
  @service declare aiContentSegmentsService: AiContentSegmentsService;
  @service declare router: RouterService;
  @service declare appService: $TSFixMe;

  @tracked activeSegments: Array<AiContentSegment> = [];
  @tracked removingAudiences = false;
  @tracked declare maxOverlayHeight: number;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.aiContentSegmentsService.loadSegments();
  }

  get segments() {
    return this.aiContentSegmentsService.segments;
  }

  get popoverStyle() {
    return trustedStyle`max-height: ${this.maxOverlayHeight}px; overflow: auto;`;
  }

  segmentIsAppliedToSelection = helper(function ([segment, selectedFragments]: [
    AiContentSegment,
    Set<ContentFragment>,
  ]): boolean {
    return Array.from(selectedFragments).every((contentFragment: any) =>
      contentFragment.aiContentSegmentIds?.includes(Number(segment.id)),
    );
  });

  noSegmentsAppliedToSelection = helper(function ([selectedFragments]: [
    Set<ContentFragment>,
  ]): boolean {
    return Array.from(selectedFragments).every(
      (contentFragment: any) => !contentFragment.aiContentSegmentIds?.length,
    );
  });

  @action async removeAllSegments() {
    this.removingAudiences = true;

    await this.args.item.applyAudience({
      segment_ids_to_add: [],
      segment_ids_to_delete: [],
      remove_all_segments: true,
    });

    this.removingAudiences = false;
  }

  @action async applySegment(segment: AiContentSegment) {
    if (this.activeSegments.includes(segment)) {
      return;
    }
    this.activeSegments.pushObject(segment);

    let segmentIdsToAdd: Array<number> = [];
    let segmentIdsToDelete: Array<number> = [];

    if (
      Array.from(this.args.item.selectedFragments).every((contentFragment) =>
        contentFragment.aiContentSegmentIds?.includes(Number(segment.id)),
      )
    ) {
      segmentIdsToDelete = [Number(segment.id)];
    } else {
      segmentIdsToAdd = [Number(segment.id)];
    }

    await this.args.item.applyAudience({
      segment_ids_to_add: segmentIdsToAdd,
      segment_ids_to_delete: segmentIdsToDelete,
      remove_all_segments: false,
    });

    this.activeSegments.removeObject(segment);
  }

  @action
  calculateMaxHeight(popperModifierData: any) {
    if (!this.isDestroying) {
      this.maxOverlayHeight = calculateMaxHeight(popperModifierData);
    }
  }

  @action redirectToAudienceSettings() {
    let url = this.router.urlFor('apps.app.settings.audiences', this.appService.app.id);
    safeWindowOpen(url, '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::BulkActions::AudienceDropdown': typeof AudienceDropdown;
    'knowledge-hub/filterable-list/bulk-actions/audience-dropdown': typeof AudienceDropdown;
  }
}
