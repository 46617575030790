/* import __COLOCATED_TEMPLATE__ from './rules-editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
// @ts-ignore no type declaration available for ember-copy
import { copy } from 'ember-copy';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type ArticleMultilingual from 'embercom/models/article-multilingual';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  isDisabled: boolean;
  article: ArticleMultilingual;
  audienceTooltipContent?: string;
  ruleset: any;
}

export default class RulesEditor extends Component<Args> {
  @action
  async onUpdatePredicates(predicates: unknown) {
    this.args.ruleset.set('predicateGroup.predicates', copy(predicates));
    this.args.ruleset.get('fetchAudiencePreviewTask').perform();
    await taskFor(this.args.article.updateSettingsMultiple).perform();
    await this.args.article.reload();
  }

  @action
  async onUpdateRolePredicates(rolePredicates: unknown) {
    this.args.ruleset.set('rolePredicateGroup.predicates', copy(rolePredicates));
    this.args.ruleset.get('fetchAudiencePreviewTask').perform();
    await taskFor(this.args.article.updateSettingsMultiple).perform();
    await this.args.article.reload();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::SidePanel::HelpCenter::Audience::RulesEditor': typeof RulesEditor;
    'knowledge-hub/content-editor/article-content/side-panel/help-center/audience/rules-editor': typeof RulesEditor;
  }
}
