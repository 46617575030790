/* import __COLOCATED_TEMPLATE__ from './button-list.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';
import { restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { ajaxDelete, put } from 'embercom/lib/ajax';
import type IntlService from 'embercom/services/intl';
import type KnowledgeHubApi from 'embercom/lib/knowledge-hub/list-api';
import type ContentFragment from 'embercom/models/content-service/content-fragment';
import { tracked } from '@glimmer/tracking';
import {
  ActionName,
  type BulkActionApiParams,
  actionParameters,
  bulkActions,
  onlyFolderActionApplicable,
  type AudienceBulkActionApiParams,
  type BulkActionConfig,
} from './bulk-action-helper';
import type Store from '@ember-data/store';
import type RouterService from '@ember/routing/router-service';
import type KnowledgeHubContentWrapper from 'embercom/models/content-service/knowledge-hub-content-wrapper';
import { FolderFilterOption } from 'embercom/lib/content-service/search-api';
import type Admin from 'embercom/models/admin';
import type Tag from 'embercom/models/tag';
import { HOME_IDENTIFIER } from 'embercom/components/articles/editor/settings-side-drawer/multi-article-group-selector-helper';

interface Args {
  selectedFragments: Set<ContentFragment>;
  unselectedFragments: Set<ContentFragment>;
  isBulkSelection: boolean;
  maxBulkUpdatable: number;
  selectedCount: number;
  listApi: KnowledgeHubApi;
  resetSelectedFragments: () => void;
}

export const MAX_BLOCKING_OPERATION_COUNT = 25;

export default class ButtonList extends Component<Args> {
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare permissionsService: any;
  @service declare store: Store;
  @service declare router: RouterService;
  @tracked declare languageCode: string;
  @tracked declare actionName: ActionName;
  @tracked showMoveToFolderModal = false;
  @tracked showConfirmDeleteModal = false;
  @tracked showUpdateAuthorModal = false;
  @tracked showMoveToCollectionModal = false;
  @tracked declare newAuthorId: string;
  @tracked showUpdateTagsModal = false;
  @tracked declare destinationFolderId: number | string;
  @tracked declare tagsToApply: Tag[];
  @tracked declare tagsToRemove: Tag[];
  @tracked declare destinationCollectionId: string;

  audienceBulkActionApiParams: AudienceBulkActionApiParams = {};

  get app() {
    return this.appService.app;
  }

  get isLoading() {
    return taskFor(this.requestBulkAction).isRunning;
  }

  get bulkActions(): BulkActionConfig[] {
    return bulkActions(
      this.intl,
      this.args.isBulkSelection,
      this.args.selectedFragments,
      this.openMoveToFolderModal,
      this.applyLanguage,
      this.applyBulkAction,
      this.applyAudience,
      this.openUpdateTagsModal,
      this.openConfirmDeleteModal,
      this.openUpdateAuthorModal,
      this.openMoveToCollectionModal,
      this.app,
    );
  }

  get onlyFolderActionApplicable() {
    return onlyFolderActionApplicable(
      this.args.selectedFragments,
      this.args.listApi.searchParams?.object_types,
    );
  }

  get onlySyncedFoldersSelected() {
    return Array.from(this.args.selectedFragments).every(
      (fragment) => !(fragment.contentWrapper as KnowledgeHubContentWrapper).isEditable,
    );
  }

  get contentWrappers(): KnowledgeHubContentWrapper[] {
    return Array.from(this.args.selectedFragments).map(
      (fragment) => fragment.contentWrapper,
    ) as KnowledgeHubContentWrapper[];
  }

  @action
  applyLanguage(locale: string): void {
    this.languageCode = locale;
    this.applyBulkAction(ActionName.UpdateLanguage);
  }

  @action
  applyMoveToFolder(destinationFolderId: number | string): void {
    this.destinationFolderId = destinationFolderId;
    this.applyBulkAction(ActionName.MoveToFolder);
    this.showMoveToFolderModal = false;
  }

  @action
  bulkDelete(): void {
    this.applyBulkAction(ActionName.Delete);
    this.showConfirmDeleteModal = false;
  }

  @action
  openMoveToFolderModal() {
    this.showMoveToFolderModal = true;
  }

  @action
  closeMoveToFolderModal() {
    if (this.showMoveToFolderModal) {
      this.showMoveToFolderModal = false;
    }
  }

  @action openUpdateTagsModal() {
    this.showUpdateTagsModal = true;
  }

  @action closeUpdateTagsModal() {
    if (this.showUpdateTagsModal) {
      this.showUpdateTagsModal = false;
    }
  }

  @action async performBulkTag(tagsToApply: Tag[], tagsToRemove: Tag[]) {
    this.tagsToApply = tagsToApply;
    this.tagsToRemove = tagsToRemove;
    await this.applyBulkAction(ActionName.UpdateTags);
    this.showUpdateTagsModal = false;
  }

  @action
  openConfirmDeleteModal() {
    this.showConfirmDeleteModal = true;
  }

  @action
  closeConfirmDeleteModal() {
    if (this.showConfirmDeleteModal) {
      this.showConfirmDeleteModal = false;
    }
  }

  @action async applyBulkUpdateAuthor(author: Admin) {
    this.newAuthorId = author.id;
    await this.applyBulkAction(ActionName.UpdateAuthor);
    this.closeUpdateAuthorModal();
  }

  @action openUpdateAuthorModal() {
    this.showUpdateAuthorModal = true;
  }

  @action closeUpdateAuthorModal() {
    if (this.showUpdateAuthorModal) {
      this.showUpdateAuthorModal = false;
    }
  }

  @action
  async applyAudience(audienceBulkActionApiParams: AudienceBulkActionApiParams): Promise<void> {
    this.audienceBulkActionApiParams = audienceBulkActionApiParams;
    await this.applyBulkAction(ActionName.UpdateAudience);
  }

  @action openMoveToCollectionModal() {
    this.showMoveToCollectionModal = true;
  }

  @action closeMoveToCollectionModal() {
    if (this.showMoveToCollectionModal) {
      this.showMoveToCollectionModal = false;
    }
  }

  @action async performBulkMoveToCollection(collectionId: string, helpCenterId?: string) {
    this.destinationCollectionId = collectionId;

    if (helpCenterId === HOME_IDENTIFIER) {
      this.destinationCollectionId = HOME_IDENTIFIER;
    }
    await this.applyBulkAction(ActionName.MoveToCollection);
    this.closeMoveToCollectionModal();
  }

  @action async applyBulkAction(actionName: ActionName) {
    let { requiredPermission } = actionParameters[actionName];
    try {
      await this.permissionsService.checkPermission(requiredPermission);
    } catch (e) {
      return;
    }

    this.actionName = actionName;

    await taskFor(this.requestBulkAction).perform(actionName);
    if (actionName === ActionName.MoveToFolder || actionName === ActionName.Delete) {
      // The selected fragments stay selected unless this is called
      // This is only a problem when moving content to another folder
      this.args.resetSelectedFragments();
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: actionName,
      section: 'knowledge_hub',
      context: 'all_content',
      place: 'bulk_actions',
    });
  }

  get isLargeBulkSelection() {
    return (
      this.bulkActionParams.is_bulk_selection ||
      (this.bulkActionParams.contents_to_update &&
        this.bulkActionParams.contents_to_update.length > MAX_BLOCKING_OPERATION_COUNT)
    );
  }

  @restartableTask private *requestBulkAction(actionName: ActionName) {
    let { endpoint } = actionParameters[actionName];
    try {
      if (this.isBulkDeleteAction) {
        yield ajaxDelete(`/ember/knowledge_base/bulk_actions/${endpoint}`, this.bulkActionParams);
      } else {
        yield put(`/ember/knowledge_base/bulk_actions/${endpoint}`, this.bulkActionParams);
      }

      if (this.isLargeBulkSelection) {
        this.showAsyncNotification();
      }
    } catch (e) {
      if (
        e.jqXHR.status === 402 &&
        e.jqXHR.responseJSON.errors[0].required_feature[0] === 'help_center'
      ) {
        this.notificationsService.notifyError(
          this.intl.t('knowledge-hub.filterable-list.bulk-actions.missing-help-center-feature'),
        );
        return;
      }
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.filterable-list.bulk-actions.failure-banner'),
      );
    }
  }

  private showAsyncNotification(): void {
    let message: string;
    if (this.isChangeLanguageAction) {
      message = this.intl.t(
        `knowledge-hub.filterable-list.bulk-actions.${this.actionName}.async-success-banner`,
        {
          language: this.intl.t(`app.lib.languages.language.${this.languageCode}`),
        },
      );
    } else if (this.isMoveToFolderAction) {
      message = this.moveToFolderSuccessMessage();
    } else {
      message = this.intl.t(
        `knowledge-hub.filterable-list.bulk-actions.${this.actionName}.async-success-banner`,
      );
    }

    this.notificationsService.notifyConfirmation(message);
  }

  private moveToFolderSuccessMessage() {
    let folderName = '';
    let folderLink = '';
    if (this.destinationFolderId === 'root') {
      folderName = this.intl.t('knowledge-hub.content');
      folderLink = this.router.urlFor('apps.app.knowledge-hub.all-content');
    } else {
      let folder = this.store.peekRecord('content-service/folder', this.destinationFolderId);
      folderName = folder.displayName;
      folderLink = folder.url;
    }

    let message = this.intl.t(
      `knowledge-hub.filterable-list.bulk-actions.move-to-folder.async-success-banner`,
      {
        folderName,
        folderLink,
        htmlSafe: true,
      },
    );
    return message;
  }

  get isChangeLanguageAction() {
    return this.actionName === ActionName.UpdateLanguage && this.languageCode;
  }

  get isMoveToFolderAction() {
    return this.actionName === ActionName.MoveToFolder && this.destinationFolderId;
  }

  get isBulkDeleteAction() {
    return this.actionName === ActionName.Delete;
  }

  get bulkActionParams() {
    let params: BulkActionApiParams = {
      app_id: this.app.id,
      is_bulk_selection: this.args.isBulkSelection,
      contents_to_update: null,
      unselected_tuples: null,
      query: null,
    };

    if (this.args.isBulkSelection) {
      params.unselected_tuples = this.tuplesFromFragments(this.args.unselectedFragments);
      params.query = this.args.listApi.searchParams || this.args.listApi.defaultSearchParams;
      if (params.query.folder_filter_option === FolderFilterOption.None) {
        delete params.query.folder_filter_option;
      }
    } else {
      params.contents_to_update = this.tuplesFromFragments(this.args.selectedFragments);
    }

    if (this.isChangeLanguageAction) {
      params.language_code = this.languageCode;
    }

    if (this.isMoveToFolderAction) {
      params.destination_folder_id = this.destinationFolderId;
    }

    if (this.actionName === ActionName.UpdateAudience) {
      params = { ...params, ...this.audienceBulkActionApiParams };
    }

    if (this.actionName === ActionName.UpdateAuthor) {
      params.author_id = this.newAuthorId;
    }

    if (this.actionName === ActionName.UpdateTags) {
      params.tag_ids_to_apply = this.tagsToApply.map((tag) => tag.id);
      params.tag_ids_to_remove = this.tagsToRemove.map((tag) => tag.id);
    }

    if (this.actionName === ActionName.MoveToCollection) {
      params.collection_id = this.destinationCollectionId;
    }

    return params;
  }

  tuplesFromFragments(fragments: Set<ContentFragment>) {
    return Array.from(fragments).map((fragment) => ({
      entity_id: fragment.contentId,
      entity_type: fragment.contentType,
    }));
  }

  get articlePublishAction() {
    return 'article-publish' as ActionName;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::BulkActions::ButtonList': typeof ButtonList;
    'knowledge-hub/filterable-list/bulk-actions/button-list': typeof ButtonList;
  }
}
