/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import templateOnlyComponent from '@ember/component/template-only';
import type ExternalContent from 'embercom/models/external-content';

interface Args {
  activeContent: ExternalContent;
  editActiveContent: () => void;
  showSidePanel: boolean;
  toggleSidePanel: () => void;
}

const Header = templateOnlyComponent<Args>();
export default Header;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Snippet::View::Header': typeof Header;
    'knowledge-hub/content-editor/snippet/view/header': typeof Header;
  }
}
