/* import __COLOCATED_TEMPLATE__ from './fin-default-availability.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import templateOnlyComponent from '@ember/component/template-only';
import type ConversationExtractionSettings from 'embercom/models/content-service/conversation-extraction-settings';

interface FinDefaultAvailabilityArgs {
  conversationExtractionSettings?: ConversationExtractionSettings;
}

const FinDefaultAvailability = templateOnlyComponent<FinDefaultAvailabilityArgs>();
export default FinDefaultAvailability;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Conversational::Settings::FinDefaultAvailability': typeof FinDefaultAvailability;
  }
}
