/* import __COLOCATED_TEMPLATE__ from './drawer-editor.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { DeduplicatedAsyncData } from 'embercom/resources/utils/async-data';
import {
  ContentAction,
  type LocalizedKnowledgeContent,
} from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type Model from '@ember-data/model';
import {
  KNOWLEDGE_HUB_ALLOWED_URL_PARAM_CONTENT_TYPES,
  KNOWLEDGE_HUB_CONTENT_TYPES_TO_DATA_STORES,
} from 'embercom/lib/knowledge-hub/constants';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { EntityType } from 'embercom/models/data/entity-types';
import type InternalArticle from 'embercom/models/content-service/internal-article';
import type ArticleContent from 'embercom/models/articles/article-content';
import { next } from '@ember/runloop';

export enum EditorMode {
  NEW = 'new',
  EDIT = 'edit',
  VIEW = 'view',
}

interface Signature {
  Args: {
    onClose: () => void;
    activeContentId?: number;
    activeContentType?: string;
    editorMode?: EditorMode;
  };
}

export default class DrawerEditor extends Component<Signature> {
  @tracked isFullScreen = false;

  @service declare router: RouterService;
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare permissionsService: any;

  constructor(owner: unknown, args: Signature['Args']) {
    super(owner, args);
    taskFor(this.fetchUnchangingKnowledgeHubData).perform();
  }

  get editorMode() {
    return this.args.editorMode || EditorMode.VIEW;
  }

  get activeContent() {
    return this.knowledgeHubEditorService.activeContentModel;
  }

  get app() {
    return this.appService.app;
  }

  get showSideDrawer(): boolean {
    return !!this.args.activeContentId && !!this.args.activeContentType;
  }

  @task({ drop: true })
  *fetchUnchangingKnowledgeHubData() {
    // This function should only be used to fetch data that doesn't change after it's loaded
    // For example, This won't be re-called on transitions such as when the active content changes.
    // It should not be used to fetch data that relies on the active content id, type or editor mode. Use AsyncData for that.
    yield Promise.all([
      this.knowledgeHubService.fetchAvailableLocales(),
      this.knowledgeHubService.fetchFoldersOnce(),
      this.knowledgeHubService.fetchArticleCollections(),
    ]);
  }

  get loadingUnchangingKnowledgeHubData(): boolean {
    return taskFor(this.fetchUnchangingKnowledgeHubData).isRunning;
  }

  private activeContentLoader = DeduplicatedAsyncData(
    this,
    () => [this.args.activeContentId, this.args.activeContentType, this.editorMode],
    async (activeContentId, activeContentType, editorMode) => {
      if (!activeContentId || !activeContentType) {
        return null;
      }

      if (!KNOWLEDGE_HUB_ALLOWED_URL_PARAM_CONTENT_TYPES.includes(activeContentType)) {
        this.notificationsService.notifyError(
          this.intl.t('knowledge-hub.errors.view.unknown-content-type'),
        );
        // Schedule the onClose call to run after the current computation
        next(() => this.args.onClose());
        return null;
      }

      switch (editorMode) {
        case EditorMode.VIEW:
          return await this.fetchViewModeActiveContent(activeContentId, activeContentType);
        case EditorMode.EDIT:
          return await this.fetchEditModeActiveContent(activeContentId, activeContentType);
      }

      return null;
    },
  );

  get loadingActiveContent(): boolean {
    return this.activeContentLoader.isLoading;
  }

  get loadedActiveContentExists(): boolean {
    return this.activeContentLoader.value !== null && this.activeContentLoader.value !== undefined;
  }

  async fetchViewModeActiveContent(
    activeContentId: number,
    activeContentType: string,
  ): Promise<(LocalizedKnowledgeContent & Model) | null> {
    let additionalParams = {};
    // we want to fetch the live article-content version for articles
    if (activeContentType === 'article') {
      additionalParams = {
        include: 'live_version',
      };
    }

    let record: (LocalizedKnowledgeContent & Model) | null = null;
    try {
      record = await this.knowledgeHubService.findContent({
        contentId: activeContentId,
        contentTypeName: activeContentType,
        findRecordParams: additionalParams,
      });
    } catch (error) {
      this.notifyErrorAndCloseDrawer();
      return null;
    }

    if (!record) {
      this.notifyErrorAndCloseDrawer();
      return null;
    }

    await record.loadRelatedContent(ContentAction.VIEW);
    this.knowledgeHubEditorService.registerActiveContent(record);
    this.knowledgeHubEditorService.trackAnalyticsEvent('viewed');
    return record;
  }

  async fetchEditModeActiveContent(
    activeContentId: number,
    activeContentType: string,
  ): Promise<(LocalizedKnowledgeContent & Model) | null> {
    let record: (LocalizedKnowledgeContent & Model) | null = null;
    try {
      record = await this.store.findRecord(
        KNOWLEDGE_HUB_CONTENT_TYPES_TO_DATA_STORES[activeContentType],
        activeContentId,
        { reload: true },
      );
    } catch (error) {
      this.notifyErrorAndCloseDrawer();
      return null;
    }

    if (!record) {
      this.notifyErrorAndCloseDrawer();
      return null;
    }

    if (!this.permissionsService.currentAdminCan(record.requiredEditPermissionForKnowledgeHub)) {
      this.notifyErrorAndCloseDrawer(this.intl.t('knowledge-hub.errors.edit.permission-required'));
      return null;
    }

    if (this.modelIsSynced(record)) {
      let errorKey =
        record.entityType === EntityType.InternalArticle
          ? 'synced-internal-article'
          : 'synced-public-article';
      // Notify error and transition to view mode
      this.notificationsService.notifyError(this.intl.t(`knowledge-hub.errors.edit.${errorKey}`));
      this.updateQueryParams({ editorMode: EditorMode.VIEW });
      return null;
    }

    if (record.entityType === EntityType.ArticleContent) {
      record.rollbackAttributes(); // Article content has a weird behaviour with autosave where it ends up having dirty attributes due to the overriding of the `save` method
    }

    await record.loadRelatedContent(ContentAction.EDIT);

    this.knowledgeHubEditorService.registerActiveContent(record);
    return record;
  }

  @action
  updateQueryParams(params: Record<string, any>) {
    this.router.transitionTo({ queryParams: params });
  }

  @action
  editModeToggle() {
    if (this.editorMode === EditorMode.VIEW) {
      this.updateQueryParams({ editorMode: EditorMode.EDIT });
    } else {
      this.updateQueryParams({ editorMode: EditorMode.VIEW });
    }
  }

  @action
  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
  }

  private notifyErrorAndCloseDrawer(
    errorMessage = this.intl.t('knowledge-hub.errors.view.unknown-content-type'),
  ) {
    this.notificationsService.notifyError(errorMessage);
    this.args.onClose();
  }

  private modelIsSynced(record: LocalizedKnowledgeContent & Model): boolean {
    return (
      (record.entityType === EntityType.InternalArticle && (record as InternalArticle).isSynced) ||
      (record.entityType === EntityType.ArticleContent &&
        !(record as unknown as ArticleContent).isEditable)
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::DrawerEditor': typeof DrawerEditor;
    'knowledge-hub/content-editor/drawer-editor': typeof DrawerEditor;
  }
}
