/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import templateOnlyComponent from '@ember/component/template-only';
import type InternalArticle from 'embercom/models/content-service/internal-article';

interface Args {
  activeContent: InternalArticle;
  defaultThemeColor: string;
}

const Editor = templateOnlyComponent<Args>();
export default Editor;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::InternalNote::View::Editor': typeof Editor;
    'knowledge-hub/content-editor/internal-note/view/editor': typeof Editor;
  }
}
