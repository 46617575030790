/* import __COLOCATED_TEMPLATE__ from './more-sources.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { AiContentState } from 'embercom/lib/ai-content-library/constants';
import { type KnowledgeHubApiQueryParams } from 'embercom/lib/knowledge-hub/list-api';
import { EntityType } from 'embercom/models/data/entity-types';
import { objectIcons } from 'embercom/models/data/matching-system/matching-constants';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { type OverviewSectionArgs } from '../../overview';
import { type ContentFilter } from '../types';
import { type SourceRow } from './section';

type EntityTypeConfig = {
  entityType: EntityType;
  visibleFor: ContentFilter[];
  customOnActionButtonClick?: () => void;
};

const PAYWALLS: Partial<Record<EntityType, { paywallFeatureKey: string }>> = {};

export default class MoreSources extends Component<OverviewSectionArgs> {
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;

  get entityTypesConfig(): EntityTypeConfig[] {
    return [
      {
        entityType: EntityType.ContentSnippet,
        visibleFor: ['all', 'ai-agent', 'ai-copilot'],
      },
      {
        entityType: EntityType.FileSourceContent,
        visibleFor: ['all', 'ai-agent', 'ai-copilot'],
        customOnActionButtonClick: () => this.args.onAddSource('file-upload'),
      },
      {
        entityType: EntityType.Answer,
        visibleFor: ['all', 'ai-agent'],
      },
    ];
  }

  get filteredEntityTypes() {
    return this.entityTypesConfig.filter((config) =>
      config.visibleFor.includes(this.args.contentFilter),
    );
  }

  get rows(): SourceRow[] {
    return this.filteredEntityTypes.map((config) => this.allContentRow(config));
  }

  allContentRow({ entityType, customOnActionButtonClick }: EntityTypeConfig): SourceRow {
    return {
      icon: objectIcons[entityType],
      statusIcon: this.contentCount(entityType) > 0 ? 'check' : undefined,
      title: this.intl.t(`knowledge-hub.overview.sections.${entityType}.rows.active.title`),
      onTitleClick: () =>
        this.knowledgeHubService.goToContent(entityType, this.AIFilterQueryParams),
      status: this.intl.t(`knowledge-hub.overview.sections.${entityType}.rows.active.description`, {
        count: this.contentCount(entityType),
        contentFilter: this.intl.t(
          `knowledge-hub.overview.sections.content-filter.${this.args.contentFilter}`,
        ),
      }),
      onActionButtonClick: () =>
        customOnActionButtonClick
          ? customOnActionButtonClick()
          : this.knowledgeHubService.createContent(entityType),
      actionButtonLabel: this.intl.t(
        `knowledge-hub.overview.sections.${entityType}.rows.active.action.label`,
      ),
      paywallFeatureKey: PAYWALLS[entityType]?.paywallFeatureKey,
    };
  }

  get AIFilterQueryParams(): KnowledgeHubApiQueryParams {
    if (this.args.contentFilter === 'ai-agent') {
      return {
        chatbotState: AiContentState.USED_BY_FIN,
      };
    } else if (this.args.contentFilter === 'ai-copilot') {
      return {
        copilotState: AiContentState.USED_BY_FIN,
      };
    }
    return {};
  }

  contentCount(entityType: EntityType) {
    let contentSummary = this.args.knowledgeUsageSummary[entityType];
    if (!contentSummary) {
      return 0;
    }
    let { agent, copilot, all } = contentSummary;
    switch (this.args.contentFilter) {
      case 'all':
        return all || 0;
      case 'ai-agent':
        return agent || 0;
      case 'ai-copilot':
        return copilot || 0;
      case 'help-center':
        return all || 0;
      default:
        return 0;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Sections::MoreSources': typeof MoreSources;
    'knowledge-hub/overview/sections/more-sources': typeof MoreSources;
  }
}
