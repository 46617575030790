/* import __COLOCATED_TEMPLATE__ from './link-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';
import type ExternalContent from 'embercom/models/external-content';

interface Args {
  activeContent: ExternalContent;
}

const LinkSection = templateOnlyComponent<Args>();
export default LinkSection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ExternalContent::LinkSection': typeof LinkSection;
    'knowledge-hub/content-editor/ExternalContent/link-section': typeof LinkSection;
  }
}
