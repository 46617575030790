/* import __COLOCATED_TEMPLATE__ from './collection-picker-modal.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { getCollectionPickerModalOptions } from './collection-picker-modal-options';
import ArticleGroup from 'embercom/models/articles/article-group';
import type Store from '@ember-data/store';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    onModalClose: () => void;
    onClickStartFromScratch: () => void;
    helpCenterId: string;
    addRootCollection: (collectionId: string) => void;
    refreshData: () => void;
  };
}

export default class CollectionPickerModal extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare intercomEventService: any;
  @tracked selectedOptionIds: string[] = [];

  @action
  optionPicked(optionId: string) {
    if (this.selectedOptionIds.includes(optionId)) {
      this.selectedOptionIds = this.selectedOptionIds.filter((option) => option !== optionId);
    } else {
      this.selectedOptionIds = [...this.selectedOptionIds, optionId];
    }
  }

  @action getOptionStyles(optionId: string) {
    if (this.selectedOptionIds.includes(optionId)) {
      return {
        bgColor: 'bg-blue',
        textColor: 'text-white',
      };
    } else {
      return {
        bgColor: 'bg-gray-lightest',
        textColor: 'text-black',
      };
    }
  }

  @action
  async createCollections() {
    await taskFor(this.createCollectionsTask).perform();
    this.args.refreshData();
    this.args.onModalClose();
  }

  @task({ drop: true })
  *createCollectionsTask(): TaskGenerator<void> {
    let selectedOptions = getCollectionPickerModalOptions(this.intl).filter((option) =>
      this.selectedOptionIds.includes(option.id),
    );

    let promises = selectedOptions.map(async (option) => {
      let collection = ArticleGroup.createNewFolder(
        this.store.peekRecord('articles/article-group', 'home'),
        undefined,
        this.args.helpCenterId,
      );
      collection.name = option.label;
      collection.description = option.description;
      collection.icon = option.icon;
      await collection.save();
    });

    yield Promise.all(promises);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'create_collections',
      object: 'collection',
      place: 'collection_picker_modal',
      section: 'help_center_settings',
      count: this.selectedOptionIds.length,
      values: this.selectedOptionIds,
    });
  }

  get collectionPickerModalOptions() {
    return getCollectionPickerModalOptions(this.intl);
  }

  get createCollectionsButtonDisabled() {
    return this.selectedOptionIds.length === 0 || taskFor(this.createCollectionsTask).isRunning;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::CollectionPickerModal': typeof CollectionPickerModal;
    'knowledge-hub/content-editor/shared/collection-picker-modal': typeof CollectionPickerModal;
  }
}
