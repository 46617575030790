/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { type ContentFilter } from '../types';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  currentTab: ContentFilter;
  openSetupModal: () => void;
  openCreateCollectionModal: () => void;
}

type ListItem = {
  name: string;
  done: boolean;
  onClick: () => void;
};

type Checklist = {
  all: ListItem[];
  'ai-agent': ListItem[];
  'ai-copilot': ListItem[];
  'help-center': ListItem[];
};

type AIType = 'agent' | 'copilot';

export default class Card extends Component<Args> {
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare helpCenterService: $TSFixMe;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;

  get checklistToRender() {
    return this.checklist[this.args.currentTab];
  }

  get hasCompletedChecklist() {
    switch (this.args.currentTab) {
      case 'ai-agent': {
        return (
          this.knowledgeHubService.hasAddedMinimunRequiredSources('agent') &&
          this.knowledgeHubService.overviewChecklistAttributes.ai_agent_enabled
        );
      }
      case 'ai-copilot': {
        return (
          this.knowledgeHubService.hasAddedMinimunRequiredSources('copilot') &&
          this.knowledgeHubService.overviewChecklistAttributes.used_ai_copilot
        );
      }
      case 'help-center': {
        return this.checklist['help-center'].every((check) => check.done);
      }
      default: {
        // all tab doesn't have any checklist items
        return false;
      }
    }
  }

  private get checklist(): Checklist {
    return {
      all: [],
      'ai-agent': [
        this.addAtLeastOneSource('agent'),
        this.setUpAgentAndGoLive(),
        this.addMoreSources('agent'),
      ],
      'ai-copilot': [
        this.addAtLeastOneSource('copilot'),
        this.startUsingAICopilot(),
        this.addMoreSources('copilot'),
      ],
      'help-center': [
        this.addAtLeastOneHelpCenterCollection(),
        this.publishAnArticle(),
        this.setHelpCenterLive(),
      ],
    };
  }

  private addAtLeastOneSource(aiType: AIType): ListItem {
    return {
      name: this.intl.t('knowledge-hub.overview.getting-started-checklist.list.add-source'),
      done: this.knowledgeHubService.hasAddedMinimunRequiredSources(aiType),
      onClick: this.args.openSetupModal,
    };
  }

  private addMoreSources(aiType: AIType): ListItem {
    return {
      name: this.intl.t(
        `knowledge-hub.overview.getting-started-checklist.list.optimize-ai-${aiType}`,
      ),
      done: this.knowledgeHubService.hasAddedMinimunRequiredSources(aiType),
      onClick: this.args.openSetupModal,
    };
  }

  private setUpAgentAndGoLive(): ListItem {
    return {
      name: this.intl.t('knowledge-hub.overview.getting-started-checklist.list.test-ai-agent'),
      done: this.knowledgeHubService.overviewChecklistAttributes.ai_agent_enabled,
      onClick: () => {
        let url = this.router.urlFor('apps.app.fin-ai-agent.setup');
        safeWindowOpen(url, '_blank');
      },
    };
  }

  private startUsingAICopilot(): ListItem {
    return {
      name: this.intl.t(
        'knowledge-hub.overview.getting-started-checklist.list.start-using-ai-copilot',
      ),
      done: this.knowledgeHubService.overviewChecklistAttributes.used_ai_copilot,
      onClick: () => {
        let url = this.router.urlFor('apps.app.inbox', {
          queryParams: { open_copilot: true },
        });
        safeWindowOpen(url, '_blank');
      },
    };
  }

  private addAtLeastOneHelpCenterCollection(): ListItem {
    return {
      name: this.intl.t(
        'knowledge-hub.overview.getting-started-checklist.list.add-help-center-collection',
      ),
      done: this.knowledgeHubService.overviewChecklistAttributes.has_help_center_collections,
      onClick: () => {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'clicked',
          object: 'create_first_collection_step',
          place: 'help_center_checklist',
          section: 'knowledge_sources',
        });

        this.args.openCreateCollectionModal();
      },
    };
  }

  private publishAnArticle(): ListItem {
    return {
      name: this.intl.t('knowledge-hub.overview.getting-started-checklist.list.publish-article'),
      done: this.knowledgeHubService.overviewChecklistAttributes
        .has_published_article_within_collection,
      onClick: () => {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'clicked',
          object: 'publish_article_step',
          place: 'help_center_checklist',
          section: 'knowledge_sources',
        });

        let url = this.router.urlFor('apps.app.knowledge-hub.new', 'article');
        safeWindowOpen(url, '_blank');
      },
    };
  }

  private setHelpCenterLive(): ListItem {
    return {
      name: this.intl.t(
        'knowledge-hub.overview.getting-started-checklist.list.set-help-center-live',
      ),
      done: this.hasLiveHelpCenter,
      onClick: () => {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'clicked',
          object: 'set_help_center_live_step',
          place: 'help_center_checklist',
          section: 'knowledge_sources',
        });

        let url = this.router.urlFor(
          'apps.app.settings.helpcenter.workspace-helpcenter.collections',
          this.helpCenterService.defaultSite.id,
        );
        safeWindowOpen(url, '_blank');
      },
    };
  }

  private get hasLiveHelpCenter(): boolean {
    return this.helpCenterService.allLiveSites.length > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::GettingStartedChecklist::Card': typeof Card;
    'knowledge-hub/overview/getting-started-checklist/card': typeof Card;
  }
}
