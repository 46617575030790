/* import __COLOCATED_TEMPLATE__ from './folder-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import type Folder from 'embercom/models/content-service/folder';
import { inject as service } from '@ember/service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

interface Args {
  folderId: string;
}

export default class FolderCell extends Component<Args> {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare store: any;

  get folder(): Folder | undefined {
    if (this.args.folderId) {
      return this.store.peekRecord(`content-service/folder`, this.args.folderId);
    }
    return undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::FolderCell': typeof FolderCell;
    'knowledge-hub/filterable-list/table/cells/folder-cell': typeof FolderCell;
  }
}
