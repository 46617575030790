/* import __COLOCATED_TEMPLATE__ from './help-center-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import type ArticleContent from 'embercom/models/articles/article-content';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type ArticleMultilingual from 'embercom/models/article-multilingual';

interface Args {
  activeContent: ArticleContent;
}

export default class HelpCenterSection extends Component<Args> {
  @service declare helpCenterService: any;
  @service declare appService: $TSFixMe;
  @tracked article: ArticleMultilingual;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.article = this.args.activeContent.article;
  }

  get hasHelpCenter() {
    return this.helpCenterService.allSites.length > 0;
  }

  get shouldShowHelpCenterStatusBadge() {
    return (
      this.helpCenterService.allSites.length === 1 &&
      this.helpCenterService.allLiveSites.length === 0
    );
  }

  get isHelpCenterAudienceFeatureFlagEnabled() {
    return this.appService.app.showHelpCenterAudienceSettingsInKnowledgeHub;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::SidePanel::HelpCenterSection': typeof HelpCenterSection;
    'knowledge-hub/content-editor/article-content/side-panel/help-center-section': typeof HelpCenterSection;
  }
}
