/* import __COLOCATED_TEMPLATE__ from './url-source-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';

interface Args {
  doSearch: (status?: string) => void;
  urlSourceId?: string;
  activeFilter?: string;
  removeFilter: (status?: string) => void;
  urlSources: Array<ContentImportSource>;
}

export default class UrlSourceFilter extends Component<Args> {
  @service declare intl: IntlService;

  get isOpen() {
    return this.args.activeFilter === 'urlSource' && !this.args.urlSourceId;
  }

  get sources() {
    return [
      ...this.args.urlSources.map((source) => {
        return { text: source.truncatedUrl, value: source.id };
      }),
    ];
  }

  get urlSourceName() {
    let source = this.sources.find(
      (source) => Number(source.value) === Number(this.args.urlSourceId),
    );
    return !source || !source.value
      ? this.intl.t('knowledge-hub.filterable-list.filters.url-source.any-url-source')
      : this.intl.t('knowledge-hub.filterable-list.filters.url-source.url-source-is', {
          source: source.text,
        });
  }

  @action removeFilter() {
    this.args.doSearch();
    this.args.removeFilter();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::UrlSourceFilter': typeof UrlSourceFilter;
    'knowledge-hub/filterable-list/filters/url-source-filter': typeof UrlSourceFilter;
  }
}
