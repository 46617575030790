/* import __COLOCATED_TEMPLATE__ from './collections-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';

interface Args {
  doSearch: (collectionIds?: string[]) => void;
  selectedValues?: string[];
  activeFilter?: string;
  removeFilter: (collection?: string) => void;
}

export default class CollectionsFilter extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked selectedValues: string[] = this.args.selectedValues || [];

  get isOpen() {
    return this.args.activeFilter === 'collectionIds' && !this.args.selectedValues;
  }

  get collectionOptions() {
    return this.collectionDescendantsList('home');
  }

  get details() {
    let count = this.selectedValues.length;

    return this.intl.t('articles.shared.group_filter.details', {
      firstItem: count ? this.getDisplayName(this.selectedValues[0]) : '',
      count,
      additional: count - 1,
    });
  }

  private getDisplayName(value: string) {
    let matched = this.items.find((e) => e.value === value);
    if (!matched) {
      return '';
    }
    return matched.text;
  }

  private collectionDescendantsList(collectionId: string) {
    let result: any[] = [];
    this.store
      .peekRecord('articles/article-group', collectionId)
      .get('children')
      .forEach((child: any) => {
        result.push({
          text: child.name,
          value: child.id,
          icon: child.icon,
          type: child.type,
        });
        result = result.concat(this.collectionDescendantsList(child.id));
      });
    return result;
  }

  get items() {
    let result = [
      {
        text: this.intl.t('articles.shared.group_filter.no_collection_label'),
        value: '',
        type: 'collection',
        isSelected: this.selectedValues ? this.selectedValues.includes('') : false,
        component: 'articles/shared/article-group-filter-option',
      },
    ];
    result.push(
      ...this.collectionOptions.map((item) => ({
        text: item.text,
        value: item.value,
        type: item.type,
        isSelected: this.selectedValues ? this.selectedValues.includes(item.value) : false,
        component: 'articles/shared/article-group-filter-option',
      })),
    );
    return result;
  }

  @action onSelectionChange(selectedValues: string[]) {
    this.selectedValues = selectedValues;

    if (this.selectedValues.length === 1 && this.selectedValues[0] === '') {
      this.args.doSearch([]);
      return;
    }
    if (this.selectedValues.length === 0) {
      this.args.doSearch(undefined);
      return;
    }
    this.args.doSearch(this.selectedValues);
  }

  @action removeFilter() {
    this.args.doSearch(undefined);
    this.args.removeFilter();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::CollectionsFilter': typeof CollectionsFilter;
    'knowledge-hub/filterable-list/filters/collections-filter': typeof CollectionsFilter;
  }
}
