/* import __COLOCATED_TEMPLATE__ from './keyboard-shortcuts-modal.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class KeyboardShortcutsModal extends Component {
  @service modalService;
  @service appService;

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.appService.app.currentAdmin;
  }

  @action
  closeModal() {
    this.modalService.closeModal();
  }
}
